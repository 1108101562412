import { Component, ViewEncapsulation, ViewChild, Input, OnChanges } from '@angular/core';
import {
  ApexChart,
  ApexStroke,
  ApexDataLabels,
  ApexXAxis,
  ApexTooltip,
  ApexPlotOptions,
  ApexYAxis,
  ApexFill,
  ApexMarkers,
  ApexNonAxisChartSeries,
  ApexLegend,
  ApexResponsive,
  ApexStates
} from 'ng-apexcharts';

import { StatisticService } from 'app/service/statistic/statistic.service';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { DatePipe } from '@angular/common';

export interface ChartOptions2 {
  // Apex-non-axis-chart-series
  series?: ApexNonAxisChartSeries;
  chart?: ApexChart;
  stroke?: ApexStroke;
  tooltip?: ApexTooltip;
  dataLabels?: ApexDataLabels;
  fill?: ApexFill;
  colors?: string[];
  legend?: ApexLegend;
  labels?: any;
  plotOptions?: ApexPlotOptions;
  responsive?: ApexResponsive[];
  markers?: ApexMarkers[];
  xaxis?: ApexXAxis;
  yaxis?: ApexYAxis;
  states?: ApexStates;
}

@Component({
  selector: 'app-by-contact-channel',
  templateUrl: './by-contact-channel.component.html',
  styleUrls: ['./by-contact-channel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ByContactChannelComponent implements OnChanges {

  @Input() period;
  @Input() disable_period;
  @Input() campaign;
  @Input() seller;

  @ViewChild('byChannelRef') byChannelRef: any;
  public byChannel: Partial<ChartOptions2>;

  public byChannelData

  constructor(private statisticService: StatisticService, private datepipe: DatePipe) { }

  public DateRangeOptions: FlatpickrOptions = {
    altInput: true,
    mode: 'range',
    altFormat: 'Y-n-j',
  };

  retrieveByChannel() {
    var startDate = this.datepipe.transform(this.period[0], 'yyyy-MM-dd')
    var endDate = startDate
    if (this.period[1] != null) {
      endDate = this.datepipe.transform(this.period[1], 'yyyy-MM-dd')
    }
    this.statisticService.byChannel(startDate, endDate, this.campaign, this.seller)
      .subscribe({
        next: (data) => {
          this.byChannelData = data
          var total = this.byChannelData.total
          this.byChannel = 
          {
            series: this.byChannelData.series,
            chart: {
              height: 450,
              type: 'donut'
            },
            colors: ['#00d4bd', '#FF9F43', '#826bf8', '#2b9bf4', '#FFA1A1', '#ffe700'],
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    name: {
                      fontSize: '2rem',
                      fontFamily: 'Montserrat'
                    },
                    value: {
                      fontSize: '2rem',
                      fontFamily: 'Montserrat',
                      formatter: function (val) {
                        return val + ' Client(s)';
                      }
                    },
                    total: {
                      show: true,
                      fontSize: '1.5rem',
                      label: 'Total',
                      formatter: function (w) {
                        return total + ' Client(s)'
                      }
                    }
                  }
                }
              }
            },
            legend: {
              show: true,
              position: 'bottom'
            },
            labels: this.byChannelData.labels,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    height: 300
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              }
            ]
          }
        },
        error: (e) => console.error(e)
      });
  }

  ngOnChanges(): void {
    this.DateRangeOptions.defaultDate = this.period
    if (this.disable_period == true) {
      this.DateRangeOptions.enable = this.period
    }
    
    this.retrieveByChannel();
  }

}
