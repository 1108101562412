<div class="text-center" *ngIf="!loadedData">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<div class="content-wrapper container-xxl p-0" *ngIf="loadedData">
    <div class="content-body">
        <div class="card">
            <div class="card-header">
                <h3 class="text-primary badge badge-light-primary" style="padding: 1rem 1rem;font-size: 120%;">
                    <i data-feather="list" class="font-medium-3"></i> Liste des roles
                </h3>
            </div>
        </div>

        <div class="card" *ngFor="let role of roles">
            <div class="card-header">
                <div class="card-actions"></div>
            </div>
            <div class="card-body row">
                <div class="col-md-2">{{role.title}}</div>
                <div class="col-md-8">
                    <div *ngFor="let item of role.access">
                        -{{ item.pageTitle }}:
                        <span *ngFor="let action of item.pageActions">
                            <container-element [ngSwitch]="true">
                                <some-element *ngSwitchCase="action == 'read'">
                                    <span class="badge badge-light-primary">Consulter</span>
                                </some-element>
                                <some-element *ngSwitchCase="action == 'add'">
                                    <span class="badge badge-light-primary">Ajouter</span>
                                </some-element>
                                <some-element *ngSwitchCase="action == 'edit'">
                                    <span class="badge badge-light-primary">Modifier</span>
                                </some-element>
                                <some-element *ngSwitchCase="action == 'delete'">
                                    <span class="badge badge-light-primary">Supprimer</span>
                                </some-element>
                                <some-element *ngSwitchCase="action == 'all'">
                                    <span class="badge badge-light-primary">Tout</span>
                                </some-element>
                            </container-element>
                        </span>
                    </div>
                </div>
                <div class="col-md-2">
                    <button type="button" (click)="modalOpen(modalRoleForm, role)"
                        class="btn btn-raised btn-outline-primary btn-sm mr-1" rippleEffect>
                        <i data-feather="edit"></i>
                    </button>
                    <button type="button" (click)="confirmDeleteRole(role)"
                        class="btn btn-raised btn-outline-danger btn-sm" rippleEffect>
                        <i data-feather="trash"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Role Modal -->
<ng-template #modalRoleForm let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">
            {{ currentRole ? ('Mettre à jour ' + currentRole.title) : 'Nouveau role'}}
        </h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="roleForm" (ngSubmit)="submitRole()">
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <label>Titre: </label>
            <div class="form-group">
                <input type="text" class="form-control" formControlName="title" placeholder="Titre"
                    [ngClass]="{ 'is-invalid': roleSubmitted && r.title.errors }" />
                <div *ngIf="roleSubmitted && r.title.errors" class="invalid-feedback">
                    <div *ngIf="r.title.errors.required">Titre est requis</div>
                </div>
            </div>
            <div *ngFor="let form of pagesFormHolder; let i = index">
                <form [formGroup]="form">
                    <div class="row">
                        <div class="col-md-5">
                            <label>Page: </label>
                            <div class="form-group">
                                <ng-select formControlName="pageTitle" placeholder="Page">
                                    <ng-option *ngFor="let page of pages" [value]="page.title">
                                        {{page.title}}
                                    </ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <label>Actions: </label>
                            <div class="form-group">
                                <ng-select formControlName="pageActions" placeholder="Actions" multiple="true">
                                    <ng-option value="read">Consulter</ng-option>
                                    <ng-option value="add">Ajouter</ng-option>
                                    <ng-option value="edit">Modifier</ng-option>
                                    <ng-option value="delete">Supprimer</ng-option>
                                    <ng-option value="all">Tout</ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <button class="btn btn-outline-danger text-nowrap px-1 mt-2" (click)="deletePage(form)">
                                <i data-feather="x"></i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button class="btn btn-icon btn-primary" type="button" (click)="addPage()" rippleEffect>
                        <i data-feather="plus" class="mr-25"></i>
                        <span>Ajoute</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" [disabled]="loadingSubmitRole" class="btn btn-primary">
                <span *ngIf="loadingSubmitRole" class="spinner-border spinner-border-sm mr-1"></span>
                Enregistrer
            </button>
        </div>
    </form>
</ng-template>
<!-- / Role Modal -->