<div class="card">
    <div
        class="card-header d-flex flex-sm-row flex-column justify-content-md-between align-items-start justify-content-start">
        <div>
            <h4 class="card-title mb-25">Coût par prospect et par client</h4>
        </div>
        <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1" *ngIf="period && period[0]">
            De {{period[0]}} à {{period[1]}}
        </div>
    </div>
    <div class="card-body" *ngIf="costHistogramData">
        <div id="bar-chart" #costHistogramRef>
            <apx-chart [series]="costHistogram.series" [chart]="costHistogram.chart" [dataLabels]="costHistogram.dataLabels"
                [plotOptions]="costHistogram.plotOptions" [xaxis]="costHistogram.xaxis" [colors]="costHistogram.colors"
                [grid]="costHistogram.grid"></apx-chart>
        </div>
    </div>
</div>