import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CenterService {

  private _refreshCenter = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshCenter() {
    return this._refreshCenter;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/api/center/list`);
  }

  getActive() {
    return this._httpClient.get(`${environment.apiUrl}/api/center/list_by_status`);
  }

  create(title) {
    return this._httpClient
      .post(`${environment.apiUrl}/api/center/add`, { title })
      .pipe(
        tap(() => {
          this._refreshCenter.next()
        })
      );
  }

  update(id, title) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/center/${id}/edit`, { title })
      .pipe(
        tap(() => {
          this._refreshCenter.next()
        })
      );
  }

  updateStatut(id, statut) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/center/${id}/statut`, { statut })
      .pipe(
        tap(() => {
          this._refreshCenter.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/api/center/${id}/delete`)
      .pipe(
        tap(() => {
          this._refreshCenter.next()
        })
      );
  }

}
