import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CampaignService } from 'app/service/campaign/campaign.service';
import { StatisticService } from 'app/service/statistic/statistic.service';

@Component({
  selector: 'app-campaign-detail',
  templateUrl: './campaign-detail.component.html',
  styleUrls: ['./campaign-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CampaignDetailComponent implements OnInit {

  public currentCampaign
  public period = []
  public campaign_period = []
  public cost

  constructor(private route: ActivatedRoute, private campaignService: CampaignService,
    private statisticService: StatisticService, private datepipe: DatePipe) { }

  public campaignDateOptions = {
    altInput: true,
    mode: 'range',
    defaultDate: [],
    minDate: null,
    maxDate: null,
    altFormat: 'Y-n-j',
  };

  getSales() {
    this.statisticService.campaignSales(this.currentCampaign.id)
      .subscribe({
        next: (data) => {
          this.cost = data;
        },
        error: (e) => console.error(e)
      });
  }

  retrieveCampaign() {
    let id = this.route.snapshot.paramMap.get('id');
    this.campaignService.get(id)
      .subscribe({
        next: (data) => {
          this.currentCampaign = data;
          var today = new Date()
          var start_date = new Date(this.currentCampaign.start_date)
          var end = new Date(start_date.setDate(start_date.getDate() + 30))
          if (end > today) {
            end = today
          }else{
            end = new Date(this.currentCampaign.end_date)
          }
          this.campaignDateOptions.minDate = this.currentCampaign.start_date
          this.campaignDateOptions.maxDate = this.datepipe.transform(end, 'yyyy-MM-dd')
          this.period = [this.currentCampaign.start_date, this.datepipe.transform(end, 'yyyy-MM-dd')]
          this.getSales()
          this.changePeriod()
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.retrieveCampaign();
  }

  changePeriod() {
    if (this.period[1]) {
      this.campaign_period = [this.datepipe.transform(this.period[0], 'yyyy-MM-dd'), this.datepipe.transform(this.period[1], 'yyyy-MM-dd')]
      this.campaignDateOptions.defaultDate = this.campaign_period
    }
  }

}
