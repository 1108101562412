<div class="card">
    <div
        class="card-header d-flex flex-sm-row flex-column justify-content-md-between align-items-start justify-content-start">
        <div>
            <h4 class="card-title mb-25">Histogramme : Heur d’appel + joignabilité</h4>
        </div>
        <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
            De {{period[0]}} à {{period[1]}}

            <!-- <ng2-flatpickr [config]="DateRangeOptions" name="DateRange" [(ngModel)]="period"
                (ngModelChange)="retrieveByLevel()" class="mr-1"></ng2-flatpickr> -->
        </div>
    </div>
    <div class="card-body" *ngIf="histogramData">
        <div id="bar-chart" #histogramRef>
            <apx-chart [series]="histogram.series" [chart]="histogram.chart" [dataLabels]="histogram.dataLabels"
                [plotOptions]="histogram.plotOptions" [xaxis]="histogram.xaxis" [colors]="histogram.colors"
                [grid]="histogram.grid"></apx-chart>
        </div>
    </div>
</div>