<div class="content-wrapper container-xxl p-0" *ngIf="currentCampaign">
    <div class="content-body">
        <div class="card">
            <div class="card-header">
                <h3 class="text-primary badge badge-light-primary" style="padding: 1rem 1rem;font-size: 120%;">
                    <i data-feather="bar-chart-2" class="font-medium-3"></i> Statistiques de la campagne
                    "{{currentCampaign.reference}}"
                </h3>
                <div>
                    <h6 class="text-primary">De {{currentCampaign.start_date}} à {{currentCampaign.end_date}}</h6>
                    <div class="progress-wrapper">
                        <div class="mb-25">{{currentCampaign.duration_percentage == 100 ? 'Terminée':'En cours'}}
                            {{currentCampaign.duration_percentage}} %</div>
                        <ngb-progressbar [type]="currentCampaign.duration_percentage == 100 ? 'primary' : 'warning'"
                            [value]="currentCampaign.duration_percentage"></ngb-progressbar>
                    </div>
                </div>
                <div class="card-action"><ng2-flatpickr [config]="campaignDateOptions" name="campaignDateRange"
                        [(ngModel)]="period" (ngModelChange)="changePeriod($event)" class="mr-1"></ng2-flatpickr>
                </div>
            </div>
        </div>
        <div class="row">
            <app-number [period]="campaign_period" [disable_period]="true" [campaign]="currentCampaign.id"
                [seller]="null" class="col-9"></app-number>
            <div class="col-3 card border-success" *ngIf="cost">
                <div class="card-body">
                    <div class="media">
                        <div class="avatar bg-light-success p-1 mr-2 mb-1 rounded">
                            <div class="avatar-content">
                                <i data-feather="user" [size]="25"></i>
                            </div>
                        </div>
                        <div class="media-body my-auto">
                            <h4 class="font-weight-bolder mb-0">{{cost.cost_per_customer}} Dt</h4>
                            <p class="card-text font-small-3 mb-0">Coût par prospect</p>
                        </div>
                    </div>
                    <div class="media">
                        <div class="avatar bg-light-success p-1 mr-2 rounded">
                            <div class="avatar-content">
                                <i data-feather="user-check" [size]="25"></i>
                            </div>
                        </div>
                        <div class="media-body my-auto">
                            <h4 class="font-weight-bolder mb-0">{{cost.cost_per_converted}} Dt</h4>
                            <p class="card-text font-small-3 mb-0">Coût par client</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-customer-conversion [period]="campaign_period" [disable_period]="true" [campaign]="currentCampaign.id"
            [seller]="null"></app-customer-conversion>
        <app-customer-contacted-and-converted [period]="campaign_period" [disable_period]="false"
            [campaign]="currentCampaign.id" [seller]="null"></app-customer-contacted-and-converted>
        <app-customer-reachability [period]="campaign_period" [disable_period]="true" [campaign]="currentCampaign.id"
            [seller]="null"></app-customer-reachability>
        <app-contact-type [period]="campaign_period" [disable_period]="true" [campaign]="currentCampaign.id"
            [seller]="null" class="col-6"></app-contact-type>
        <app-by-reachability [period]="campaign_period" [disable_period]="true" [campaign]="currentCampaign.id"
            [seller]="null" class="col-6"></app-by-reachability>


        <div class="row">
            <app-by-governorate [period]="campaign_period" [disable_period]="true" [campaign]="currentCampaign.id"
                [seller]="null" class="col-6"></app-by-governorate>
            <app-by-contact-channel [period]="campaign_period" [disable_period]="true" [campaign]="currentCampaign.id"
                [seller]="null" class="col-6"></app-by-contact-channel>
        </div>

        <div class="row">
            <app-by-level [period]="campaign_period" [disable_period]="true" [campaign]="currentCampaign.id"
                [seller]="null" class="col-6"></app-by-level>
            <app-by-gender [period]="campaign_period" [disable_period]="true" [campaign]="currentCampaign.id"
                [seller]="null" class="col-6"></app-by-gender>
        </div>
    </div>
</div>