import { CoreMenu } from '@core/types';
import { RoleService } from 'app/service/role/role.service';

//? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface
var tes = RoleService.role;

export var menu: CoreMenu[] = [
    {
        id: 'crm',
        type: 'section',
        title: '',
        //role: ['ROLE_USER', 'ROLE_MANAGER'],
        icon: 'package',
        children: [
            {
                id: 'dashboard',
                title: 'Tablea de bord',
                //translate: 'MENU.DASHBOARD.ANALYTICS',
                type: 'item',
                icon: 'home',
                role: ['ROLE_USER', 'ROLE_MANAGER', 'ROLE_SELLER', 'ROLE_CENTER_SELLER'],
                url: 'dashboard'
            },
            {
                id: 'user',
                title: 'Utilisateur',
                //translate: 'MENU.DASHBOARD.ANALYTICS',
                type: 'item',
                role: ['ROLE_USER', 'ROLE_MANAGER'],
                icon: 'user',
                url: 'user'
            },
            {
                id: 'seller',
                title: 'Vendeur',
                //translate: 'MENU.DASHBOARD.ANALYTICS',
                type: 'item',
                role: ['ROLE_USER', 'ROLE_MANAGER'],
                icon: 'user-plus',
                url: 'seller'
            },
            {
                id: 'role',
                title: 'Role',
                //translate: 'MENU.DASHBOARD.ANALYTICS',
                type: 'item',
                role: ['ROLE_USER'],
                icon: 'circle',
                url: 'role'
            },
            {
                id: 'parameter',
                title: 'Paramètres',
                //translate: 'MENU.DASHBOARD.COLLAPSIBLE',
                type: 'collapsible',
                role: ['ROLE_USER', 'ROLE_MANAGER', 'ROLE_SELLER'],
                icon: 'sliders',
                children: [
                    {
                        id: 'pack',
                        title: 'Pack',
                        //translate: 'MENU.DASHBOARD.ANALYTICS',
                        type: 'item',
                        icon: 'circle',
                        url: 'pack'
                    },
                    {
                        id: 'level',
                        title: 'Niveau',
                        //translate: 'MENU.DASHBOARD.ANALYTICS',
                        type: 'item',
                        icon: 'circle',
                        url: 'level'
                    },
                    {
                        id: 'governorate',
                        title: 'Governorat',
                        //translate: 'MENU.DASHBOARD.ANALYTICS',
                        type: 'item',
                        icon: 'circle',
                        url: 'governorate'
                    },
                    {
                        id: 'time_frame',
                        title: 'Plage horaire',
                        //translate: 'MENU.DASHBOARD.ANALYTICS',
                        type: 'item',
                        icon: 'circle',
                        url: 'time_frame'
                    },
                    {
                        id: 'heard_about_us',
                        title: 'Prise de contact',
                        //translate: 'MENU.DASHBOARD.ANALYTICS',
                        type: 'item',
                        icon: 'circle',
                        url: 'heard_about_us'
                    },
                    {
                        id: 'contact_channel',
                        title: 'Canal de contact',
                        //translate: 'MENU.DASHBOARD.ANALYTICS',
                        type: 'item',
                        icon: 'circle',
                        url: 'contact_channel'
                    },
                    {
                        id: 'reflection_reason',
                        title: 'Motif de réflexion',
                        //translate: 'MENU.DASHBOARD.ANALYTICS',
                        type: 'item',
                        icon: 'circle',
                        url: 'reflection_reason'
                    },
                    {
                        id: 'rejection_reason',
                        title: 'Motif de refus',
                        //translate: 'MENU.DASHBOARD.ANALYTICS',
                        type: 'item',
                        icon: 'circle',
                        url: 'rejection_reason'
                    },
                    {
                        id: 'ticket_object',
                        title: 'Objet de ticket',
                        //translate: 'MENU.DASHBOARD.ANALYTICS',
                        type: 'item',
                        icon: 'circle',
                        url: 'ticket_object'
                    },
                ]
            },
            {
                id: 'campaign',
                title: 'Campagne',
                //translate: 'MENU.DASHBOARD.ANALYTICS',
                type: 'item',
                role: ['ROLE_USER', 'ROLE_MANAGER', 'ROLE_COM', 'ROLE_SELLER'],
                icon: 'send',
                url: 'campaign'
            },
            {
                id: 'sms_history',
                title: 'Sms',
                //translate: 'MENU.DASHBOARD.ANALYTICS',
                type: 'item',
                role: ['ROLE_USER', 'ROLE_MANAGER', 'ROLE_COM', 'ROLE_SELLER'],
                icon: 'message-circle',
                url: 'sms'
            },
            {
                id: 'center',
                title: 'Centre',
                //translate: 'MENU.DASHBOARD.ANALYTICS',
                type: 'item',
                role: ['ROLE_USER', 'ROLE_MANAGER', 'ROLE_SELLER'],
                icon: 'home',
                url: 'center'
            },
            {
                id: 'subject',
                title: 'Matière',
                //translate: 'MENU.DASHBOARD.ANALYTICS',
                type: 'item',
                role: ['ROLE_USER', 'ROLE_MANAGER', 'ROLE_SELLER'],
                icon: 'book-open',
                url: 'subject'
            },
            {
                id: 'free_session',
                title: 'Séance Gratuite',
                //translate: 'MENU.DASHBOARD.ANALYTICS',
                type: 'item',
                role: ['ROLE_USER', 'ROLE_MANAGER', 'ROLE_SELLER'],
                icon: 'list',
                url: 'free_session'
            },
            {
                id: 'free_subscription',
                title: 'Abonnement Gratuit',
                //translate: 'MENU.DASHBOARD.ANALYTICS',
                type: 'item',
                role: ['ROLE_USER', 'ROLE_MANAGER', 'ROLE_SELLER'],
                icon: 'list',
                url: 'free_subscription'
            },
            {
                id: 'active',
                title: 'Client actif',
                //translate: 'MENU.DASHBOARD.ANALYTICS',
                type: 'item',
                role: ['ROLE_USER'],
                icon: 'circle',
                url: 'list/customer/active'
            },
            {
                id: 'customer',
                title: 'Clients',
                //translate: 'MENU.DASHBOARD.COLLAPSIBLE',
                type: 'collapsible',
                role: ['ROLE_USER', 'ROLE_MANAGER', 'ROLE_SELLER'],
                icon: 'users',
                children: [
                    {
                        id: 'all',
                        title: 'Liste des clients',
                        //translate: 'MENU.DASHBOARD.ANALYTICS',
                        type: 'item',
                        icon: 'circle',
                        url: 'customer'
                    },
                    {
                        id: 'to_call',
                        title: 'Liste à contacter',
                        //translate: 'MENU.DASHBOARD.ANALYTICS',
                        type: 'collapsible',
                        icon: 'circle',
                        children: [
                            {
                                id: 'reachable',
                                title: 'Joignables',
                                //translate: 'MENU.PAGES.AUTH.LOGIN2',
                                type: 'item',
                                url: 'list/customer/to_call',
                            },
                            {
                                id: 'unreachable',
                                title: 'Injoignables',
                                //translate: 'MENU.PAGES.AUTH.LOGIN1',
                                type: 'item',
                                url: 'list/customer/unreachable',
                            },
                        ]
                    },
                    {
                        id: 'callback_appointment',
                        title: 'RDV de rappel',
                        //translate: 'MENU.DASHBOARD.ANALYTICS',
                        type: 'item',
                        role: ['ROLE_USER', 'ROLE_MANAGER', 'ROLE_SELLER'],
                        icon: 'circle',
                        url: 'list/customer/with_callback_appointment'
                    },
                    {
                        id: 'active',
                        title: 'Client actif',
                        //translate: 'MENU.DASHBOARD.ANALYTICS',
                        type: 'item',
                        role: ['ROLE_USER', 'ROLE_MANAGER', 'ROLE_SELLER'],
                        icon: 'circle',
                        url: 'list/customer/active'
                    },
                    {
                        id: 'warm',
                        title: 'Client chaud',
                        //translate: 'MENU.DASHBOARD.ANALYTICS',
                        type: 'item',
                        role: ['ROLE_USER', 'ROLE_MANAGER', 'ROLE_SELLER'],
                        icon: 'circle',
                        url: 'list/customer/warm'
                    },
                    {
                        id: 'without_contact',
                        title: 'Sans contact',
                        //translate: 'MENU.DASHBOARD.ANALYTICS',
                        type: 'item',
                        role: ['ROLE_USER', 'ROLE_MANAGER', 'ROLE_SELLER'],
                        icon: 'circle',
                        url: 'list/customer/without_contact'
                    },
                    {
                        id: 'from_platform',
                        title: 'De la platforme',
                        //translate: 'MENU.DASHBOARD.ANALYTICS',
                        type: 'item',
                        role: ['ROLE_USER', 'ROLE_MANAGER', 'ROLE_SELLER'],
                        icon: 'circle',
                        url: 'list/customer/from_platform'
                    },
                ]
            },
        ]
    },
];