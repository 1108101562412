<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section class="app-user-edit">
            <div class="card">
                <div class="card-body">
                    <ul ngbNav #nav="ngbNav" class="nav nav-pills">
                        <li ngbNavItem>
                            <a ngbNavLink class="d-flex align-items-center"><i data-feather="lock"
                                    class="mr-sm-50"></i><span class="d-none d-sm-block">Mettre à jour mon mot de passe
                                </span></a>
                            <ng-template ngbNavContent>
                                <!-- Account Tab starts -->
                                <div class="tab-pane active" id="account" aria-labelledby="account-tab" role="tabpanel">
                                    <form [formGroup]="editUserForm" class="validate-form"
                                        (ngSubmit)="updateUser()">
                                        <!-- <div class="row">
                                            <div class="col-12 col-sm-12">
                                                <div class="form-group">
                                                    <label>Nom d'utilisateur</label>
                                                    <input type="text" class="form-control" formControlName="username" />
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="row">
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="account-new-password">Nouveau mot de passe</label>
                                                    <div class="input-group form-password-toggle input-group-merge">
                                                        <input [type]="passwordTextTypeNew ? 'text' : 'password'"
                                                            formControlName="password" id="account-new-password"
                                                            name="new-password" class="form-control"
                                                            placeholder="Nouveau mot de passe" />
                                                        <div class="input-group-append">
                                                            <span class="input-group-text cursor-pointer"><i
                                                                    class="feather font-small-4" [ngClass]="{
                                                  'icon-eye-off': passwordTextTypeNew,
                                                  'icon-eye': !passwordTextTypeNew
                                                }" (click)="togglePasswordTextTypeNew()"></i></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="account-retype-new-password">Confirmer le nouveau mot de
                                                        passe</label>
                                                    <div class="input-group form-password-toggle input-group-merge">
                                                        <input [type]="passwordTextTypeRetype ? 'text' : 'password'"
                                                            formControlName="confirmPassword" class="form-control"
                                                            id="account-retype-new-password" name="confirm-new-password"
                                                            placeholder="Nouveau mot de passe" />
                                                        <div class="input-group-append">
                                                            <span class="input-group-text cursor-pointer"><i
                                                                    class="feather font-small-4" [ngClass]="{
                                                  'icon-eye-off': passwordTextTypeRetype,
                                                  'icon-eye': !passwordTextTypeRetype
                                                }" (click)="togglePasswordTextTypeRetype()"></i></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 text-right">
                                                <button type="submit" class="btn btn-primary mr-1 mt-1"
                                                    [disabled]="loadingSubmitUser" rippleEffect><span
                                                        *ngIf="loadingSubmitUser"
                                                        class="spinner-border spinner-border-sm mr-1"></span>Enregistrer</button>
                                                <button type="reset" class="btn btn-outline-secondary mt-1"
                                                    rippleEffect>Annuler</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
                </div>
            </div>
        </section>
    </div>
</div>