import { Component, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-navbar-cart',
  templateUrl: './navbar-cart.component.html'
})
export class NavbarCartComponent implements OnInit {
  // Public
  public products = [];
  public cartList = [];
  public cartListLength;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   *
   * @param {EcommerceService} _ecommerceService
   */
  constructor() {
    this._unsubscribeAll = new Subject();
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Remove From Cart
   *
   * @param product
   */
  removeFromCart(product) {
    if (product.isInCart === true) {
      // this._ecommerceService.removeFromCart(product.id).then(res => {
      //   product.isInCart = false;
      // });
    }
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Get Products
    // this._ecommerceService.getProducts();

    // // Get Cart List
    // this._ecommerceService.getCartList();

    // // Subscribe to Cart List
    // this._ecommerceService.onCartListChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
    //   this.cartList = res;
    //   this.cartListLength = this.cartList.length;
    // });

    // Subscribe to ProductList change
    // this._ecommerceService.onProductListChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
    //   this.products = res;

    //   if (this.products.length) {
    //     // update product is in CartList : Boolean
    //     this.products.forEach(product => {
    //       product.isInCart = this.cartList.findIndex(p => p.productId === product.id) > -1;
    //     });
    //   }
    // });
  }
}
