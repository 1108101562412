import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FreeSessionService {

  private _refreshSession = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshSession() {
    return this._refreshSession;
  }

  getByPeriodAndSubjects(period, subjects) {
    return this._httpClient.post(`${environment.apiUrl}/api/free_session/list/${period}`, { subjects });
  }

  getByCustomer(customer_id) {
    return this._httpClient.get(`${environment.apiUrl}/api/free_session/${customer_id}/by_customer`);
  }

  create(customer, subject, date, comment) {
    return this._httpClient
      .post(`${environment.apiUrl}/api/free_session/add`, { customer, subject, date, comment })
      .pipe(
        tap(() => {
          this._refreshSession.next()
        })
      );
  }

  update(id, subject, date) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/free_session/${id}/edit`, { subject, date })
      .pipe(
        tap(() => {
          this._refreshSession.next()
        })
      );
  }

  updateParticipation(id, participation) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/free_session/${id}/participation`, { participation })
      .pipe(
        tap(() => {
          this._refreshSession.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/api/free_session/${id}/delete`)
      .pipe(
        tap(() => {
          this._refreshSession.next()
        })
      );
  }

}
