import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Swal from 'sweetalert2';

import { ToastService } from 'app/service/toast/toast.service';
import { RejectionReasonService } from 'app/service/rejection-reason/rejection-reason.service';

@Component({
  selector: 'app-rejection-reason',
  templateUrl: './rejection-reason.component.html',
  styleUrls: ['./rejection-reason.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RejectionReasonComponent implements OnInit {

  public loadedData = false

  public rejection_reasons;
  public currentRejectionReason
  private tempRejectionReasonData = [];
  public rejection_reasonRows: any;
  public exportCSVData

  public ColumnMode = ColumnMode;
  public basicSelectedOption: number = 25;
  public SelectionType = SelectionType;
  public selected = [];
  public chkBoxSelected = [];

  public rejection_reasonForm: FormGroup;
  public rejection_reasonSubmitted = false
  public loadingSubmitRejectionReason = false

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    private rejection_reasonService: RejectionReasonService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder
  ) { }

  // convenience getter for easy access to rejection_reason form fields
  get p() {
    return this.rejection_reasonForm.controls;
  }

  deleteRejectionReason(res, id) {
    if (res.value == true) {
      this.rejection_reasonService.delete(id)
        .subscribe({
          next: (response) => {
            this.toastService.error('Motif de refus supprimé', 'Motif supprimé avec succès')
          },
          error: (e) => console.error(e)
        });
    }
  }
  updateRejectionReason() {
    this.rejection_reasonService.update(this.currentRejectionReason.id, this.p.title.value)
      .subscribe({
        next: (response) => {
          this.rejection_reasonSubmitted = false
          this.loadingSubmitRejectionReason = false
          this.modalService.dismissAll()
          this.toastService.info('Motif de refus mis à jour', 'Motif mis à jour avec succès')
          this.rejection_reasonForm.reset()
        },
        error: (e) => {
          this.loadingSubmitRejectionReason = false
          this.rejection_reasonSubmitted = false
          console.error(e)
        }
      });
  }
  createRejectionReason() {
    this.rejection_reasonSubmitted = true
    if (this.rejection_reasonForm.invalid) {
      return
    } else {
      this.loadingSubmitRejectionReason = true
      this.rejection_reasonService.create(this.p.title.value)
        .subscribe({
          next: (response) => {
            this.rejection_reasonSubmitted = false
            this.loadingSubmitRejectionReason = false
            this.modalService.dismissAll()
            this.toastService.success('Motif de refus créé', 'Motif créé avec succès')
            this.rejection_reasonForm.reset()
          },
          error: (e) => {
            this.loadingSubmitRejectionReason = false
            this.rejection_reasonSubmitted = false
            console.error(e)
          }
        });
    }
  }
  submitRejectionReason() {
    if (this.currentRejectionReason) {
      this.updateRejectionReason()
    } else {
      this.createRejectionReason()
    }
  }

  retrieveRejectionReasons(): void {
    this.rejection_reasonService.getAll()
      .subscribe({
        next: (data) => {
          this.rejection_reasons = data;
          this.loadedData = true
          this.tempRejectionReasonData = this.rejection_reasons;
          this.rejection_reasonRows = this.rejection_reasons;
          this.exportCSVData = this.rejection_reasons;
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.rejection_reasonService.refreshRejectionReason.subscribe(() => {
      this.retrieveRejectionReasons()
    })
    this.retrieveRejectionReasons();

    //rejection_reason
    this.rejection_reasonForm = this._formBuilder.group({
      title: ['', [Validators.required]],
    });
  }

  modalOpen(modalForm, rejection_reason) {
    this.currentRejectionReason = null
    this.rejection_reasonForm.reset()
    this.modalService.open(modalForm);
    if (rejection_reason) {
      this.currentRejectionReason = rejection_reason
      this.rejection_reasonForm.patchValue({
        title: rejection_reason.title,
      });
    }
  }

  filterRejectionReasons(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.tempRejectionReasonData.filter(function (d) {
      if (d.title.toLowerCase().includes(val)) {
        return d
      }
    });
    this.rejection_reasonRows = temp;
  }

  confirmDeleteRejectionReason(rejection_reason) {
    Swal.fire({
      title: `Vous êtes sûr de vouloir supprimer ${rejection_reason.title}?`,
      text: 'Vous ne serez pas en mesure de la récupérer!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Oui',
      cancelButtonText: 'Annuler',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(res => this.deleteRejectionReason(res, rejection_reason.id));
  }
}
