import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { FakeDbService } from '@fake-db/fake-db.service';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CsvModule } from '@ctrl/ngx-csv';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';

import { ToastrModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';
import { ContextMenuModule } from '@ctrl/ngx-rightclick';

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';

import { coreConfig } from 'app/app-config';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { JwtInterceptor, ErrorInterceptor } from 'app/auth/helpers';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

import { PackComponent } from './main/pack/pack.component';
import { CampaignComponent } from './main/campaign/campaign.component';
import { LevelComponent } from './main/level/level.component';
import { CenterComponent } from './main/center/center.component';
import { TimeFrameComponent } from './main/time-frame/time-frame.component';
import { GovernorateComponent } from './main/governorate/governorate.component';
import { SubjectComponent } from './main/subject/subject.component';

import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FreeSessionComponent } from './main/free-session/free-session.component';
import { RejectionReasonComponent } from './main/rejection-reason/rejection-reason.component';
import { CampaignDetailComponent } from './main/campaign/campaign-detail/campaign-detail.component';
import { StatisticsModule } from './main/statistics/statistics.module';
import { DashboardComponent } from './main/dashboard/dashboard.component';
import { UserComponent } from './main/user/user.component';
import { DashboardSellerComponent } from './main/dashboard-seller/dashboard-seller.component';
import { RoleComponent } from './main/role/role.component';
import { SellerComponent } from './main/seller/seller.component';
import { ContactChannelComponent } from './main/contact-channel/contact-channel.component';
import { HeardAboutUsComponent } from './main/heard-about-us/heard-about-us.component';
import { ProfileComponent } from './main/profile/profile.component';
import { SmsHistoryComponent } from './main/sms-history/sms-history.component';
import { FreeSubscriptionComponent } from './main/free-subscription/free-subscription.component';
import { SellerContactComponent } from './main/seller/seller-contact/seller-contact.component';
import { TicketObjectComponent } from './main/ticket-object/ticket-object.component';
import { SellerHistoryComponent } from './main/seller/seller-history/seller-history.component';
import { ReflectionReasonComponent } from './main/reflection-reason/reflection-reason.component';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./main/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard-seller/:id',
    component: DashboardSellerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'pack',
    component: PackComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'level',
    component: LevelComponent,
    data: { animation: 'toasts' },
    canActivate: [AuthGuard]
  },
  {
    path: 'governorate',
    component: GovernorateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'time_frame',
    component: TimeFrameComponent,
    data: { animation: 'toasts' },
    canActivate: [AuthGuard]
  },
  {
    path: 'heard_about_us',
    component: HeardAboutUsComponent,
    data: { animation: 'toasts' },
    canActivate: [AuthGuard]
  },
  {
    path: 'contact_channel',
    component: ContactChannelComponent,
    data: { animation: 'toasts' },
    canActivate: [AuthGuard]
  },
  {
    path: 'reflection_reason',
    component: ReflectionReasonComponent,
    data: { animation: 'toasts' },
    canActivate: [AuthGuard]
  },
  {
    path: 'rejection_reason',
    component: RejectionReasonComponent,
    data: { animation: 'toasts' },
    canActivate: [AuthGuard]
  },
  {
    path: 'ticket_object',
    component: TicketObjectComponent,
    data: { animation: 'toasts' },
    canActivate: [AuthGuard]
  },
  {
    path: 'campaign',
    component: CampaignComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'campaign/:id/detail',
    component: CampaignDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sms',
    component: SmsHistoryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'center',
    component: CenterComponent,
    data: { animation: 'toasts' },
    canActivate: [AuthGuard]
  },
  {
    path: 'subject',
    component: SubjectComponent,
    data: { animation: 'toasts' },
    canActivate: [AuthGuard]
  },
  {
    path: 'free_session',
    component: FreeSessionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'free_subscription',
    component: FreeSubscriptionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user',
    component: UserComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'seller',
    component: SellerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'seller_contact/:id',
    component: SellerContactComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'seller_history/:id',
    component: SellerHistoryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'role',
    component: RoleComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./main/customer/customer.module').then(m => m.CustomerModule)
  },
  {
    path: 'statistics',
    loadChildren: () => import('./main/statistics/statistics.module').then(m => m.StatisticsModule)
  },
  {
    path: '**',
    redirectTo: '/login' //Error 404 - Page not found
  }
];

@NgModule({
  declarations: [
    AppComponent,
    CampaignComponent,
    LevelComponent,
    PackComponent,
    SubjectComponent,
    CenterComponent,
    TimeFrameComponent,
    GovernorateComponent,
    FreeSessionComponent,
    RejectionReasonComponent,
    CampaignDetailComponent,
    DashboardComponent,
    UserComponent,
    DashboardSellerComponent,
    RoleComponent,
    SellerComponent,
    ContactChannelComponent,
    HeardAboutUsComponent,
    ProfileComponent,
    SmsHistoryComponent,  
    FreeSubscriptionComponent,
     SellerContactComponent,
     TicketObjectComponent,
     SellerHistoryComponent,
     ReflectionReasonComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientInMemoryWebApiModule.forRoot(FakeDbService, {
      delay: 0,
      passThruUnknownUrl: true
    }),
    RouterModule.forRoot(appRoutes, {
      useHash: false,
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy'
    }),
    NgbModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot(),
    ContextMenuModule,
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,
    CardSnippetModule,
    LayoutModule,
    ContentHeaderModule,
    CsvModule,
    NgxDatatableModule,
    NgSelectModule,
    Ng2FlatpickrModule,
    StatisticsModule
  ],

  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    DatePipe,
    // ! IMPORTANT: Provider used to create fake backend, comment while using real API
    // fakeBackendProvider
  ],
  entryComponents: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
