import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Swal from 'sweetalert2';

import { SubjectService } from 'app/service/subject/subject.service';
import { ToastService } from 'app/service/toast/toast.service';

@Component({
  selector: 'app-subject',
  templateUrl: './subject.component.html',
  styleUrls: ['./subject.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubjectComponent implements OnInit {

  public loadedData = false

  public subjects;
  public currentSubject
  private tempSubjectData = [];
  public subjectRows: any;
  public exportCSVData

  public ColumnMode = ColumnMode;
  public basicSelectedOption: number = 25;
  public SelectionType = SelectionType;
  public selected = [];
  public chkBoxSelected = [];

  public subjectForm: FormGroup;
  public subjectSubmitted = false
  public loadingSubmitSubject = false

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    private subjectService: SubjectService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
  ) { }

  // convenience getter for easy access to subject form fields
  get p() {
    return this.subjectForm.controls;
  }

  deleteSubject(res, id) {
    if (res.value == true) {
      this.subjectService.delete(id)
        .subscribe({
          next: (response) => {
            this.toastService.error('Matière supprimée', 'Matière supprimée avec succès')
          },
          error: (e) => console.error(e)
        });
    }
  }
  updateSubject() {
    this.subjectService.update(this.currentSubject.id, this.p.title.value)
      .subscribe({
        next: (response) => {
          this.subjectSubmitted = false
          this.loadingSubmitSubject = false
          this.modalService.dismissAll()
          this.toastService.info('Matière mise à jour', 'Matière mise à jour avec succès')
          this.subjectForm.reset()
        },
        error: (e) => {
          this.loadingSubmitSubject = false
          this.subjectSubmitted = false
          console.error(e)
        }
      });
  }
  createSubject() {
    this.subjectSubmitted = true
    if (this.subjectForm.invalid) {
      return
    } else {
      this.loadingSubmitSubject = true
      this.subjectService.create(this.p.title.value)
        .subscribe({
          next: (response) => {
            this.subjectSubmitted = false
            this.loadingSubmitSubject = false
            this.modalService.dismissAll()
            this.toastService.success('Matière créée', 'Matière créée avec succès')
            this.subjectForm.reset()
          },
          error: (e) => {
            this.loadingSubmitSubject = false
            this.subjectSubmitted = false
            console.error(e)
          }
        });
    }
  }
  submitSubject() {
    if (this.currentSubject) {
      this.updateSubject()
    } else {
      this.createSubject()
    }
  }

  retrieveSubjects(): void {
    this.subjectService.getAll()
      .subscribe({
        next: (data) => {
          this.subjects = data;
          this.loadedData = true
          this.tempSubjectData = this.subjects;
          this.subjectRows = this.subjects;
          this.exportCSVData = this.subjects;
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.subjectService.refreshSubject.subscribe(() => {
      this.retrieveSubjects()
    })
    this.retrieveSubjects();

    //subject
    this.subjectForm = this._formBuilder.group({
      title: ['', [Validators.required]],
    });
  }

  modalOpen(modalForm, subject) {
    this.currentSubject = null
    this.subjectForm.reset()
    this.modalService.open(modalForm);
    if (subject) {
      this.currentSubject = subject
      this.subjectForm.patchValue({
        title: subject.title,
      });
    }
  }

  filterSubjects(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.tempSubjectData.filter(function (d) {
      return d.title.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.subjectRows = temp;
  }

  confirmDeleteSubject(subject) {
    Swal.fire({
      title: `Vous êtes sûr de vouloir supprimer ${subject.title}?`,
      text: 'Vous ne serez pas en mesure de la récupérer!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Oui',
      cancelButtonText: 'Annuler',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(res => this.deleteSubject(res, subject.id));
  }
}
