import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  private _refreshCampaign = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshCampaign() {
    return this._refreshCampaign;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/api/campaign/list`);
  }
  getActive() {
    return this._httpClient.get(`${environment.apiUrl}/api/campaign/list_by_status`);
  }

  get(id) {
    return this._httpClient.get(`${environment.apiUrl}/api/campaign/${id}/detail`);
  }

  filter(statut, start_date, end_date) {
    return this._httpClient
      .post(`${environment.apiUrl}/api/campaign/filter`, { statut, start_date, end_date });
  }

  create(reference, budget, start_date, end_date, packs, contact_channels) {
    return this._httpClient
      .post(`${environment.apiUrl}/api/campaign/add`, { reference, budget, start_date, end_date, packs, contact_channels })
      .pipe(
        tap(() => {
          this._refreshCampaign.next()
        })
      );
  }

  update(id, reference, budget, start_date, end_date, packs, contact_channels) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/campaign/${id}/edit`, { reference, budget, start_date, end_date, packs, contact_channels })
      .pipe(
        tap(() => {
          this._refreshCampaign.next()
        })
      );
  }

  updateStatut(id, statut) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/campaign/${id}/statut`, { statut })
      .pipe(
        tap(() => {
          this._refreshCampaign.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/api/campaign/${id}/delete`)
      .pipe(
        tap(() => {
          this._refreshCampaign.next()
        })
      );
  }

}
