<div class="card">
    <div
        class="card-header d-flex flex-sm-row flex-column justify-content-md-between align-items-start justify-content-start">
        <div>
            <h4 class="card-title mb-25">Taux de joignabilité</h4>
            <span class="card-subtitle text-muted">Clients joignable</span>
        </div>
        <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
            De {{period[0]}} à {{period[1]}}
            <!-- <ng2-flatpickr [config]="DateRangeOptions" name="DateRange" [(ngModel)]="period"
                (ngModelChange)="retrieveData()" class="mr-1"></ng2-flatpickr> -->
        </div>
    </div>
    <div class="card-body">
        <div id="line-chart" #reachableCustomersRateRef *ngIf="rateData">
            <apx-chart [series]="reachableCustomersRate.series" [chart]="reachableCustomersRate.chart"
                [xaxis]="reachableCustomersRate.xaxis" [stroke]="reachableCustomersRate.stroke"
                [tooltip]="reachableCustomersRate.tooltip" [colors]="reachableCustomersRate.colors"
                [dataLabels]="reachableCustomersRate.dataLabels" [grid]="reachableCustomersRate.grid"></apx-chart>
        </div>
    </div>
</div>