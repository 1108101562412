import { Component, OnInit, ViewEncapsulation, ViewChild, Input, OnChanges } from '@angular/core';
import {
  ApexChart,
  ApexStroke,
  ApexDataLabels,
  ApexTooltip,
  ApexPlotOptions,
  ApexYAxis,
  ApexFill,
  ApexMarkers,
  ApexLegend,
  ApexAxisChartSeries,
  ApexGrid,
  ApexXAxis,
  ApexTitleSubtitle,
  ApexTheme
} from 'ng-apexcharts';

import { StatisticService } from 'app/service/statistic/statistic.service';
import { colors } from 'app/colors.const';
import { DatePipe } from '@angular/common';
import { FlatpickrOptions } from 'ng2-flatpickr';

// interface ChartOptions
export interface ChartOptions {
  series?: ApexAxisChartSeries;
  chart?: ApexChart;
  xaxis?: ApexXAxis;
  dataLabels?: ApexDataLabels;
  grid?: ApexGrid;
  stroke?: ApexStroke;
  legend?: ApexLegend;
  title?: ApexTitleSubtitle;
  colors?: string[];
  tooltip?: ApexTooltip;
  plotOptions?: ApexPlotOptions;
  yaxis?: ApexYAxis;
  fill?: ApexFill;
  labels?: string[];
  markers: ApexMarkers;
  theme: ApexTheme;
}

@Component({
  selector: 'app-contact-type',
  templateUrl: './contact-type.component.html',
  styleUrls: ['./contact-type.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactTypeComponent implements OnChanges {

  @Input() period;
  @Input() disable_period;
  @Input() campaign;
  @Input() seller;

  @ViewChild('contactTypeRef') contactTypeRef: any;
  public contactType: Partial<ChartOptions>;

  public contactTypeData

  constructor(private statisticService: StatisticService, private datepipe: DatePipe) { }

  public DateRangeOptions: FlatpickrOptions = {
    altInput: true,
    mode: 'range',
    altFormat: 'Y-n-j',
  };

  retrieveByContactType() {
    var startDate = this.datepipe.transform(this.period[0], 'yyyy-MM-dd')
    var endDate = startDate
    if (this.period[1] != null) {
      endDate = this.datepipe.transform(this.period[1], 'yyyy-MM-dd')
    }
    this.statisticService.contactType(startDate, endDate, this.campaign, this.seller)
      .subscribe({
        next: (data) => {
          this.contactTypeData = data
          this.contactType = {
            series: this.contactTypeData.series,
            chart: {
              height: 400,
              type: 'bar',
              toolbar: {
                show: false
              }
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: "35%",
                dataLabels: {
                  position: "top" // top, center, bottom
                }
              }
            },
            grid: {
              xaxis: {
                lines: {
                  show: false
                }
              }
            },
            colors: [colors.solid.danger, colors.solid.info, colors.solid.success],
            dataLabels: {
              enabled: true,
              offsetY: -20,
              style: {
                fontSize: "12px",
                fontFamily: 'Montserrat',
              }
            },
            xaxis: {
              categories: this.contactTypeData.categories
            }
          };
        },
        error: (e) => console.error(e)
      });
  }

  ngOnChanges(): void {
    this.DateRangeOptions.defaultDate = this.period
    if (this.disable_period == true) {
      this.DateRangeOptions.enable = this.period
    }

    this.retrieveByContactType();
  }

}