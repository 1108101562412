<div class="text-center" *ngIf="!loadedData">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<div class="content-wrapper container-xxl p-0" *ngIf="loadedData">
    <div class="content-body">
        <div class="card">
            <div class="card-header">
                <h3 class="text-primary badge badge-light-primary" style="padding: 1rem 1rem;font-size: 120%;">
                    <i data-feather="circle" class="font-medium-3"></i> Liste des objets
                </h3>
                <div class="card-actions">
                    <button class="btn btn-primary" (click)="modalOpen(modalTicketObjectForm)" rippleEffect>
                        <span data-feather="plus"></span> Ajouter un objet
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-12">
                    <div class="d-flex justify-content-between align-items-center m-1">
                        <label class="d-flex align-items-center">Afficher
                            <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                                <option value="5">5</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            entrées</label>
                    </div>
                </div>
                <div class="col-md-6 col-12 d-flex justify-content-md-end">
                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                        <label class="d-flex align-items-center">Recherche<input type="search" placeholder="recherche"
                                class="form-control ml-25" (keyup)="filterTicketObjects($event)"
                                (search)="filterTicketObjects($event)" /></label>
                    </div>
                </div>
            </div>

            <!-- TicketObjects Datatable -->
            <ngx-datatable [rows]="ticket_objectRows" [rowHeight]="58" class="bootstrap core-bootstrap" [limit]="5"
                [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
                [selectionType]="SelectionType.checkbox" [limit]="basicSelectedOption"
                (select)="onSelectTicketObject($event)">
                <ngx-datatable-column name="Titre" prop="title" [width]="150"></ngx-datatable-column>
                <ngx-datatable-column name="Actions" [width]="120" [sortable]="false">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        <div class="d-flex align-items-center">
                            <button type="button" (click)="modalOpen(modalTicketObjectForm, row)"
                                class="btn btn-raised btn-outline-primary btn-sm mr-1" rippleEffect>
                                <i data-feather="edit"></i>
                            </button>
                            <button type="button" (click)="confirmDeleteTicketObject(row)"
                                class="btn btn-raised btn-outline-danger btn-sm" rippleEffect>
                                <i data-feather="trash"></i>
                            </button>
                        </div>
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
            <!--/ TicketObjects Datatable -->
        </div>
    </div>
</div>

<!-- TicketObject Modal -->
<ng-template #modalTicketObjectForm let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">
            {{ currentTicketObject ? ('Mettre à jour ' + currentTicketObject.title) : 'Nouvel objet'}}
        </h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="ticket_objectForm" (ngSubmit)="submitTicketObject()">
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <label>Titre: </label>
            <div class="form-group">
                <input type="text" class="form-control" formControlName="title" placeholder="Titre"
                    [ngClass]="{ 'is-invalid': ticket_objectSubmitted && p.title.errors }" />
                <div *ngIf="ticket_objectSubmitted && p.title.errors" class="invalid-feedback">
                    <div *ngIf="p.title.errors.required">Titre est requis</div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" [disabled]="loadingSubmitTicketObject" class="btn btn-primary">
                <span *ngIf="loadingSubmitTicketObject" class="spinner-border spinner-border-sm mr-1"></span>
                Enregistrer
            </button>
        </div>
    </form>
</ng-template>
<!-- / TicketObject Modal -->