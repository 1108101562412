import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PackService {

  private _refreshPack = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshPack() {
    return this._refreshPack;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/api/pack/list`);
  }

  create(title, price, bySubject) {
    return this._httpClient
      .post(`${environment.apiUrl}/api/pack/add`, { title, price, bySubject })
      .pipe(
        tap(() => {
          this._refreshPack.next()
        })
      );
  }

  update(id, title, price, bySubject) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/pack/${id}/edit`, { title, price, bySubject })
      .pipe(
        tap(() => {
          this._refreshPack.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/api/pack/${id}/delete`)
      .pipe(
        tap(() => {
          this._refreshPack.next()
        })
      );
  }

}
