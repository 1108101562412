<div class="text-center" *ngIf="!loadedData">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<div class="content-wrapper container-xxl p-0" *ngIf="loadedData">
    <div class="content-body">
        <div class="card">
            <div class="card-header">
                <h3 class="text-primary badge badge-light-primary" style="padding: 1rem 1rem;font-size: 120%;">
                    <i data-feather="list" class="font-medium-3"></i> Liste des packs
                </h3>
                <div class="card-actions">
                    <button class="btn btn-primary" (click)="modalOpen(modalPackForm)" rippleEffect>
                        <span data-feather="plus"></span> Ajouter un pack
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-12">
                    <div class="d-flex justify-content-between align-items-center m-1">
                        <label class="d-flex align-items-center">Afficher
                            <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                                <option value="5">5</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            entrées</label>
                    </div>
                </div>
                <div class="col-md-6 col-12 d-flex justify-content-md-end">
                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                        <label class="d-flex align-items-center">Recherche<input type="search" placeholder="recherche"
                                class="form-control ml-25" (keyup)="filterPacks($event)"
                                (search)="filterPacks($event)" /></label>
                    </div>
                </div>
            </div>

            <!-- Packs Datatable -->
            <ngx-datatable [rows]="packRows" [rowHeight]="58" class="bootstrap core-bootstrap" [limit]="5"
                [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
                [selectionType]="SelectionType.checkbox" [limit]="basicSelectedOption" (select)="onSelectPack($event)">
                <ngx-datatable-column name="Titre" prop="title" [width]="150"></ngx-datatable-column>
                <ngx-datatable-column name="Prix" prop="price" [width]="150"></ngx-datatable-column>
                <ngx-datatable-column name="Type" [width]="100">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        {{ row.bySubject ? 'Par matière':'Tous les matières' }}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Actions" [width]="120" [sortable]="false">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        <div class="d-flex align-items-center">
                            <button type="button" (click)="modalOpen(modalPackForm, row)"
                                class="btn btn-raised btn-outline-primary btn-sm mr-1" rippleEffect>
                                <i data-feather="edit"></i>
                            </button>
                            <button type="button" (click)="confirmDeletePack(row)"
                                class="btn btn-raised btn-outline-danger btn-sm" rippleEffect>
                                <i data-feather="trash"></i>
                            </button>
                        </div>
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
            <!--/ Packs Datatable -->
        </div>
    </div>
</div>

<!-- Pack Modal -->
<ng-template #modalPackForm let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">
            {{ currentPack ? ('Mettre à jour ' + currentPack.title) : 'Nouveau pack'}}
        </h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="packForm" (ngSubmit)="submitPack()">
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <label>Titre: </label>
            <div class="form-group">
                <input type="text" class="form-control" formControlName="title" placeholder="Titre"
                    [ngClass]="{ 'is-invalid': packSubmitted && p.title.errors }" />
                <div *ngIf="packSubmitted && p.title.errors" class="invalid-feedback">
                    <div *ngIf="p.title.errors.required">Titre est requis</div>
                </div>
            </div>
            <label>Prix: </label>
            <div class="form-group">
                <input type="number" class="form-control" formControlName="price" placeholder="Prix"
                    [ngClass]="{ 'is-invalid': packSubmitted && p.price.errors }" />
                <div *ngIf="packSubmitted && p.price.errors" class="invalid-feedback">
                    <div *ngIf="p.price.errors.required">Prix est requis</div>
                </div>
            </div>
            <label>Type: </label>
            <div class="form-group row justify-content-center">
                <div class="custom-control custom-radio justify-content-center mr-1">
                    <input type="radio" id="customRadio2" name="bySubject" formControlName="bySubject"
                        class="custom-control-input" value="false"
                        [ngClass]="{ 'is-invalid': packSubmitted && p.bySubject.errors }" />
                    <label class="custom-control-label" for="customRadio2">Tous les matières</label>
                </div>
                <div class="custom-control custom-radio justify-content-center">
                    <input type="radio" id="customRadio1" name="bySubject" formControlName="bySubject"
                        class="custom-control-input" value="true"
                        [ngClass]="{ 'is-invalid': packSubmitted && p.bySubject.errors }" />
                    <label class="custom-control-label" for="customRadio1">Par matière</label>
                </div>
                <div *ngIf="packSubmitted && p.bySubject.errors" class="invalid-feedback">
                    <div *ngIf="p.bySubject.errors.required">Type est requis</div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" [disabled]="loadingSubmitPack" class="btn btn-primary">
                <span *ngIf="loadingSubmitPack" class="spinner-border spinner-border-sm mr-1"></span>
                Enregistrer
            </button>
        </div>
    </form>
</ng-template>
<!-- / Pack Modal -->