import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Swal from 'sweetalert2';

import { ToastService } from 'app/service/toast/toast.service';
import { ReflectionReasonService } from 'app/service/reflection-reason/reflection-reason.service';

@Component({
  selector: 'app-reflection-reason',
  templateUrl: './reflection-reason.component.html',
  styleUrls: ['./reflection-reason.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReflectionReasonComponent implements OnInit {

  public loadedData = false

  public ReflectionReasons;
  public currentReflectionReason
  private tempReflectionReasonData = [];
  public ReflectionReasonRows: any;
  public exportCSVData

  public ColumnMode = ColumnMode;
  public basicSelectedOption: number = 25;
  public SelectionType = SelectionType;
  public selected = [];
  public chkBoxSelected = [];

  public ReflectionReasonForm: FormGroup;
  public ReflectionReasonSubmitted = false
  public loadingSubmitReflectionReason = false

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    private ReflectionReasonService: ReflectionReasonService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder
  ) { }

  // convenience getter for easy access to ReflectionReason form fields
  get p() {
    return this.ReflectionReasonForm.controls;
  }

  deleteReflectionReason(res, id) {
    if (res.value == true) {
      this.ReflectionReasonService.delete(id)
        .subscribe({
          next: (response) => {
            this.toastService.error('Motif de refus supprimé', 'Motif supprimé avec succès')
          },
          error: (e) => console.error(e)
        });
    }
  }
  updateReflectionReason() {
    this.ReflectionReasonService.update(this.currentReflectionReason.id, this.p.title.value)
      .subscribe({
        next: (response) => {
          this.ReflectionReasonSubmitted = false
          this.loadingSubmitReflectionReason = false
          this.modalService.dismissAll()
          this.toastService.info('Motif de refus mis à jour', 'Motif mis à jour avec succès')
          this.ReflectionReasonForm.reset()
        },
        error: (e) => {
          this.loadingSubmitReflectionReason = false
          this.ReflectionReasonSubmitted = false
          console.error(e)
        }
      });
  }
  createReflectionReason() {
    this.ReflectionReasonSubmitted = true
    if (this.ReflectionReasonForm.invalid) {
      return
    } else {
      this.loadingSubmitReflectionReason = true
      this.ReflectionReasonService.create(this.p.title.value)
        .subscribe({
          next: (response) => {
            this.ReflectionReasonSubmitted = false
            this.loadingSubmitReflectionReason = false
            this.modalService.dismissAll()
            this.toastService.success('Motif de refus créé', 'Motif créé avec succès')
            this.ReflectionReasonForm.reset()
          },
          error: (e) => {
            this.loadingSubmitReflectionReason = false
            this.ReflectionReasonSubmitted = false
            console.error(e)
          }
        });
    }
  }
  submitReflectionReason() {
    if (this.currentReflectionReason) {
      this.updateReflectionReason()
    } else {
      this.createReflectionReason()
    }
  }

  retrieveReflectionReasons(): void {
    this.ReflectionReasonService.getAll()
      .subscribe({
        next: (data) => {
          this.ReflectionReasons = data;
          this.loadedData = true
          this.tempReflectionReasonData = this.ReflectionReasons;
          this.ReflectionReasonRows = this.ReflectionReasons;
          this.exportCSVData = this.ReflectionReasons;
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.ReflectionReasonService.refreshReflectionReason.subscribe(() => {
      this.retrieveReflectionReasons()
    })
    this.retrieveReflectionReasons();

    //ReflectionReason
    this.ReflectionReasonForm = this._formBuilder.group({
      title: ['', [Validators.required]],
    });
  }

  modalOpen(modalForm, ReflectionReason) {
    this.currentReflectionReason = null
    this.ReflectionReasonForm.reset()
    this.modalService.open(modalForm);
    if (ReflectionReason) {
      this.currentReflectionReason = ReflectionReason
      this.ReflectionReasonForm.patchValue({
        title: ReflectionReason.title,
      });
    }
  }

  filterReflectionReasons(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.tempReflectionReasonData.filter(function (d) {
      if (d.title.toLowerCase().includes(val)) {
        return d
      }
    });
    this.ReflectionReasonRows = temp;
  }

  confirmDeleteReflectionReason(ReflectionReason) {
    Swal.fire({
      title: `Vous êtes sûr de vouloir supprimer ${ReflectionReason.title}?`,
      text: 'Vous ne serez pas en mesure de la récupérer!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Oui',
      cancelButtonText: 'Annuler',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(res => this.deleteReflectionReason(res, ReflectionReason.id));
  }
}
