import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Swal from 'sweetalert2';

import { ToastService } from 'app/service/toast/toast.service';
import { TicketObjectService } from 'app/service/ticket-object/ticket-object.service';

@Component({
  selector: 'app-ticket-object',
  templateUrl: './ticket-object.component.html',
  styleUrls: ['./ticket-object.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TicketObjectComponent implements OnInit {

  public loadedData = false

  public ticket_objects;
  public currentTicketObject
  private tempTicketObjectData = [];
  public ticket_objectRows: any;
  public exportCSVData

  public ColumnMode = ColumnMode;
  public basicSelectedOption: number = 25;
  public SelectionType = SelectionType;
  public selected = [];
  public chkBoxSelected = [];

  public ticket_objectForm: FormGroup;
  public ticket_objectSubmitted = false
  public loadingSubmitTicketObject = false

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    private ticket_objectService: TicketObjectService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
  ) { }

  // convenience getter for easy access to ticket_object form fields
  get p() {
    return this.ticket_objectForm.controls;
  }

  deleteTicketObject(res, id) {
    if (res.value == true) {
      this.ticket_objectService.delete(id)
        .subscribe({
          next: (response) => {
            this.toastService.error('Objet supprimé', 'Objet supprimé avec succès')
          },
          error: (e) => console.error(e)
        });
    }
  }
  updateTicketObject() {
    this.ticket_objectService.update(this.currentTicketObject.id, this.p.title.value)
      .subscribe({
        next: (response) => {
          this.ticket_objectSubmitted = false
          this.loadingSubmitTicketObject = false
          this.modalService.dismissAll()
          this.toastService.info('Objet mis à jour', 'Objet mis à jour avec succès')
          this.ticket_objectForm.reset()
        },
        error: (e) => {
          this.loadingSubmitTicketObject = false
          this.ticket_objectSubmitted = false
          console.error(e)
        }
      });
  }
  createTicketObject() {
    this.ticket_objectSubmitted = true
    if (this.ticket_objectForm.invalid) {
      return
    } else {
      this.loadingSubmitTicketObject = true
      this.ticket_objectService.create(this.p.title.value)
        .subscribe({
          next: (response) => {
            this.ticket_objectSubmitted = false
            this.loadingSubmitTicketObject = false
            this.modalService.dismissAll()
            this.toastService.success('Objet créé', 'Objet créé avec succès')
            this.ticket_objectForm.reset()
          },
          error: (e) => {
            this.loadingSubmitTicketObject = false
            this.ticket_objectSubmitted = false
            console.error(e)
          }
        });
    }
  }
  submitTicketObject() {
    if (this.currentTicketObject) {
      this.updateTicketObject()
    } else {
      this.createTicketObject()
    }
  }

  retrieveTicketObjects(): void {
    this.ticket_objectService.getAll()
      .subscribe({
        next: (data) => {
          this.ticket_objects = data;
          this.loadedData = true
          this.tempTicketObjectData = this.ticket_objects;
          this.ticket_objectRows = this.ticket_objects;
          this.exportCSVData = this.ticket_objects;
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.ticket_objectService.refreshTicketObject.subscribe(() => {
      this.retrieveTicketObjects()
    })
    this.retrieveTicketObjects();

    //ticket_object
    this.ticket_objectForm = this._formBuilder.group({
      title: ['', [Validators.required]],
    });
  }

  modalOpen(modalForm, ticket_object) {
    this.currentTicketObject = null
    this.ticket_objectForm.reset()
    this.modalService.open(modalForm);
    if (ticket_object) {
      this.currentTicketObject = ticket_object
      this.ticket_objectForm.patchValue({
        title: ticket_object.title,
      });
    }
  }

  filterTicketObjects(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.tempTicketObjectData.filter(function (d) {
      return d.title.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.ticket_objectRows = temp;
  }

  confirmDeleteTicketObject(ticket_object) {
    Swal.fire({
      title: `Vous êtes sûr de vouloir supprimer ${ticket_object.title}?`,
      text: 'Vous ne serez pas en mesure de la récupérer!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Oui',
      cancelButtonText: 'Annuler',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(res => this.deleteTicketObject(res, ticket_object.id));
  }
}
