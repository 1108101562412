import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'app/auth/service';
import { ToastService } from 'app/service/toast/toast.service';
import { UserService } from 'app/service/user/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProfileComponent implements OnInit {

  currentUser = Object.freeze(this.authenticationService.currentUserValue);

  public currentUserData

  public passwordTextTypeNew = false;
  public passwordTextTypeRetype = false;

  public editUserForm: FormGroup;
  public userSubmitted = false
  public loadingSubmitUser = false

  constructor(private authenticationService: AuthenticationService,
    private userService: UserService,
    private _formBuilder: FormBuilder,
    private toastService: ToastService) { }

  // convenience getter for easy access to level form fields
  get u() {
    return this.editUserForm.controls;
  }

  updateUser() {
    if (this.u.password.value === this.u.confirmPassword.value) {
      this.loadingSubmitUser = true
      this.userService.updateUserData(this.currentUser.id, this.u.username.value, this.u.password.value)
        .subscribe({
          next: (response) => {
            this.userSubmitted = false
            this.loadingSubmitUser = false
            this.editUserForm.reset()
            this.toastService.info('Données mises à jour', 'Données mises à jour avec succès')
          },
          error: (e) => {
            this.loadingSubmitUser = false
            this.userSubmitted = false
            console.error(e)
          }
        });
    } else {
      this.errorAlert("Erreur", "Le mot de passe de confirmation saisi n'est pas compatible avec le mot de passe saisi, veuillez le vérifier");
    }
  }


  retrieveUserData(): void {
    this.userService.getById(this.currentUser.id)
      .subscribe({
        next: (data) => {
          this.currentUserData = data;
          this.editUserForm.get('username').setValue(this.currentUserData.username)
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.userService.refreshUser.subscribe(() => {
      this.retrieveUserData()
    })
    this.retrieveUserData();

    //user form
    this.editUserForm = this._formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]], confirmPassword: ['', [Validators.required]]
    });
  }

  /**
 * Toggle Password Text Type New
 */
  togglePasswordTextTypeNew() {
    this.passwordTextTypeNew = !this.passwordTextTypeNew;
  }

  /**
   * Toggle Password Text Type Retype
   */
  togglePasswordTextTypeRetype() {
    this.passwordTextTypeRetype = !this.passwordTextTypeRetype;
  }

  errorAlert(title, text) {
    Swal.fire({
      title: title,
      text: text,
      icon: 'error',
      confirmButtonText: 'Ok',
      customClass: {
        confirmButton: 'btn btn-primary mr-1',
        cancelButton: 'btn btn-outline-danger ms-1'
      },
      buttonsStyling: false
    })
  }

}