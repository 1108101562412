<div class="card">
    <div
        class="card-header d-flex flex-sm-row flex-column justify-content-md-between align-items-start justify-content-start">
        <div>
            <h4 class="card-title mb-25">Distribution des clients selon les canaux</h4>
        </div>
        <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
            <span class="badge badge-light-primary" *ngIf="byChannelData && byChannelData.total">{{byChannelData.total}} clients</span>
            <!-- <ng2-flatpickr [config]="DateRangeOptions" name="DateRange" [(ngModel)]="period"
                (ngModelChange)="retrieveByChannel()" class="mr-1"></ng2-flatpickr> -->
        </div>
    </div>
    <div class="card-body" *ngIf="byChannelData">
        <div id="donut-chart" #byChannelRef>
            <apx-chart [series]="byChannel.series" [chart]="byChannel.chart" [labels]="byChannel.labels"
                [plotOptions]="byChannel.plotOptions" [responsive]="byChannel.responsive" [colors]="byChannel.colors"
                [legend]="byChannel.legend"></apx-chart>
        </div>
    </div>
</div>