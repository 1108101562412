<div class="content-wrapper container-xxl p-0" *ngIf="global_period">
    <div class="content-body">
        <div class="card">
            <div class="card-header">
                <h3 class="text-primary badge badge-light-primary" style="padding: 1rem 1rem;font-size: 120%;">
                    <i data-feather="bar-chart-2" class="font-medium-3"></i> Statistiques Générales
                </h3>
                <div class="card-action">
                    <ng2-flatpickr [config]="globalDateOptions" name="globalDateRange" [(ngModel)]="period"
                        (ngModelChange)="changePeriod()" class="mr-1"></ng2-flatpickr>
                </div>
            </div>
        </div>
        <div class="row">
            <app-number [period]="global_period" [disable_period]="false" [campaign]="null" [seller]="null"
                class="col-10"></app-number>
            <div class="col-2 card border-success">
                <div class="card-body">
                    <div class="text-center">
                        <p class="card-title mt-2 mb-2">Chiffre d'affaires</p>

                        <h3 class=" font-weight-bolder" *ngIf="salesFigures">
                            {{salesFigures.amount}} Dt
                        </h3>
                    </div>
                </div>
            </div>
        </div>

        <app-customer-conversion [period]="global_period" [disable_period]="false" [campaign]="null"
            [seller]="null"></app-customer-conversion>
        <app-customer-contacted-and-converted [period]="global_period" [disable_period]="false" [campaign]="null"
            [seller]="null"></app-customer-contacted-and-converted>
        <app-customer-reachability [period]="global_period" [disable_period]="false" [campaign]="null"
            [seller]="null"></app-customer-reachability>
        <app-call-duration-histogram [period]="global_period" [disable_period]="false" [campaign]="null"
            [seller]="null"></app-call-duration-histogram>
        <app-contact-type [period]="global_period" [disable_period]="false" [campaign]="null" [seller]="null"
            class="col-7"></app-contact-type>

        <div class="row">
            <app-histogram [period]="global_period" [disable_period]="false" [campaign]="null" [seller]="null"
                class="col-7"></app-histogram>
            <app-by-reachability [period]="global_period" [disable_period]="false" [campaign]="null" [seller]="null"
                class="col-5"></app-by-reachability>
        </div>
        <div class="row">
            <app-by-governorate [period]="global_period" [disable_period]="false" [campaign]="null" [seller]="null"
                class="col-6"></app-by-governorate>
            <app-by-contact-channel [period]="global_period" [disable_period]="false" [campaign]="null" [seller]="null"
                class="col-6"></app-by-contact-channel>
        </div>
        <div class="row">
            <app-by-level [period]="global_period" [disable_period]="false" [campaign]="null" [seller]="null"
                class="col-6"></app-by-level>
            <app-by-gender [period]="global_period" [disable_period]="false" [campaign]="null" [seller]="null"
                class="col-6"></app-by-gender>
        </div>
    </div>
</div>