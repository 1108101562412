<div class="text-center" *ngIf="!loadedData">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<div class="content-wrapper container-xxl p-0" *ngIf="loadedData">
    <div class="content-body">
        <div class="card">
            <div class="card-header">
                <h3 class="text-primary badge badge-light-primary" style="padding: 1rem 1rem;font-size: 120%;">
                    <i data-feather="user" class="font-medium-3"></i> Liste des utilisateurs
                </h3>
                <div class="card-actions" *ngIf="checkAccess('add')">
                    <button class="btn btn-primary" (click)="modalOpen(modalUserForm)" rippleEffect>
                        <span data-feather="plus"></span> Ajouter un utilisateur
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-12">
                    <div class="d-flex justify-content-between align-items-center m-1">
                        <label class="d-flex align-items-center">Afficher
                            <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                                <option value="5">5</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            entrées</label>
                    </div>
                </div>
                <div class="col-md-6 col-12 d-flex justify-content-md-end">
                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                        <label class="d-flex align-items-center">Recherche<input type="search" placeholder="recherche"
                                class="form-control ml-25" (keyup)="filterUsers($event)"
                                (search)="filterUsers($event)" /></label>
                    </div>
                </div>
            </div>

            <!-- Users Datatable -->
            <ngx-datatable [rows]="userRows" [rowHeight]="58" class="bootstrap core-bootstrap" [limit]="5"
                [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
                [selectionType]="SelectionType.checkbox" [limit]="basicSelectedOption" (select)="onSelectUser($event)">
                <ngx-datatable-column name="Nom d'utilisateur" prop="username" [width]="150"></ngx-datatable-column>
                <ngx-datatable-column name="Rôle" [width]="150">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        {{row.role ? row.role.title : ''}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Actions" [width]="120" [sortable]="false">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        <div class="d-flex align-items-center">
                            <button type="button" *ngIf="checkAccess('read')" routerLink="/dashboard-seller/{{row.id}}/name/{{row.username}}"
                                class="btn btn-raised btn-outline-secondary btn-sm mr-1" rippleEffect>
                                <i data-feather="file-text"></i>
                            </button>
                            <button type="button" *ngIf="checkAccess('edit')" (click)="modalOpen(modalUserForm, row)"
                                class="btn btn-raised btn-outline-primary btn-sm mr-1" rippleEffect>
                                <i data-feather="edit"></i>
                            </button>
                            <button type="button" *ngIf="checkAccess('delete')" (click)="confirmDeleteUser(row)"
                                class="btn btn-raised btn-outline-danger btn-sm" rippleEffect>
                                <i data-feather="trash"></i>
                            </button>
                        </div>
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
            <!--/ Users Datatable -->
        </div>
    </div>
</div>

<!-- User Modal -->
<ng-template #modalUserForm let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">
            {{ currentUser ? ('Mettre à jour ' + currentUser.username) : 'Nouvel utilisateur'}}
        </h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="userForm" (ngSubmit)="submitUser()">
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <label>Nom d'utilisateur: </label>
            <div class="form-group">
                <input type="text" class="form-control" formControlName="username" placeholder="Nom d'utilisateur"
                    [ngClass]="{ 'is-invalid': userSubmitted && u.username.errors }" />
                <div *ngIf="userSubmitted && u.username.errors" class="invalid-feedback">
                    <div *ngIf="u.username.errors.required">Nom d'utilisateur est requis</div>
                </div>
            </div>
            <label>Rôle: </label>
            <div class="form-group">
                <ng-select formControlName="role" placeholder="Rôle"
                    [ngClass]="{ 'is-invalid': userSubmitted && u.role.errors }">
                    <ng-option *ngFor="let role of roles" [value]="role">{{role.title}}</ng-option>
                </ng-select>
                <div *ngIf="userSubmitted && u.role.errors" class="invalid-feedback">
                    <div *ngIf="u.role.errors.required">Role est requis</div>
                </div>
            </div>
            <div
                *ngIf="userForm.get('role').value != null && (userForm.get('role').value.value == 'ROLE_SELLER' || userForm.get('role').value.value == 'ROLE_CENTER_SELLER')">
                <label>Centre: </label>
                <div class="form-group">
                    <ng-select formControlName="center" placeholder="Centre"
                        [required]="userForm.get('role').value != null && (userForm.get('role').value.value == 'ROLE_SELLER' || userForm.get('role').value.value == 'ROLE_CENTER_SELLER')"
                        [ngClass]="{ 'is-invalid': userSubmitted && u.center.errors }">
                        <ng-option *ngFor="let center of centers" [value]="center.id">{{center.title}}</ng-option>
                    </ng-select>
                    <div *ngIf="userSubmitted && u.center.errors" class="invalid-feedback">
                        <div *ngIf="u.center.errors.required">Centre est requis</div>
                    </div>
                </div>
            </div>
            <div *ngIf="!currentUser">
                <label>Mot de passe: </label>
                <div class="form-group">
                    <input type="password" class="form-control" formControlName="password" [required]="!currentUser"
                        placeholder="Mot de passe" [ngClass]="{ 'is-invalid': userSubmitted && u.password.errors }" />
                    <div *ngIf="userSubmitted && u.password.errors" class="invalid-feedback">
                        <div *ngIf="u.password.errors.required">Mot de passe est requis</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" [disabled]="loadingSubmitUser" class="btn btn-primary">
                <span *ngIf="loadingSubmitUser" class="spinner-border spinner-border-sm mr-1"></span>
                Enregistrer
            </button>
        </div>
    </form>
</ng-template>
<!-- / User Modal -->