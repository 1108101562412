import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Swal from 'sweetalert2';

import { PackService } from 'app/service/pack/pack.service';
import { ToastService } from 'app/service/toast/toast.service';

@Component({
  selector: 'app-pack',
  templateUrl: './pack.component.html',
  styleUrls: ['./pack.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PackComponent implements OnInit {

  public loadedData = false

  public packs;
  public currentPack
  private tempPackData = [];
  public packRows: any;
  public exportCSVData

  public ColumnMode = ColumnMode;
  public basicSelectedOption: number = 25;
  public SelectionType = SelectionType;
  public selected = [];
  public chkBoxSelected = [];

  public packForm: FormGroup;
  public packSubmitted = false
  public loadingSubmitPack = false

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    private packService: PackService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder
  ) { }

  // convenience getter for easy access to pack form fields
  get p() {
    return this.packForm.controls;
  }

  deletePack(res, id) {
    if (res.value == true) {
      this.packService.delete(id)
        .subscribe({
          next: (response) => {
            this.toastService.error('Pack supprimé', 'Pack supprimé avec succès')
          },
          error: (e) => console.error(e)
        });
    }
  }
  updatePack() {
    this.packService.update(this.currentPack.id, this.p.title.value, this.p.price.value, this.p.bySubject.value == 'true' ? true : false)
      .subscribe({
        next: (response) => {
          this.packSubmitted = false
          this.loadingSubmitPack = false
          this.modalService.dismissAll()
          this.toastService.info('Pack mis à jour', 'Pack mis à jour avec succès')
          this.packForm.reset()
        },
        error: (e) => {
          this.loadingSubmitPack = false
          this.packSubmitted = false
          console.error(e)
        }
      });
  }
  createPack() {
    this.packSubmitted = true
    if (this.packForm.invalid) {
      return
    } else {
      this.loadingSubmitPack = true
      this.packService.create(this.p.title.value, this.p.price.value, this.p.bySubject.value == 'true' ? true : false)
        .subscribe({
          next: (response) => {
            this.packSubmitted = false
            this.loadingSubmitPack = false
            this.modalService.dismissAll()
            this.toastService.success('Pack créé', 'Pack créé avec succès')
            this.packForm.reset()
          },
          error: (e) => {
            this.loadingSubmitPack = false
            this.packSubmitted = false
            console.error(e)
          }
        });
    }
  }
  submitPack() {
    if (this.currentPack) {
      this.updatePack()
    } else {
      this.createPack()
    }
  }

  retrievePacks(): void {
    this.packService.getAll()
      .subscribe({
        next: (data) => {
          this.packs = data;
          this.loadedData = true
          this.tempPackData = this.packs;
          this.packRows = this.packs;
          this.exportCSVData = this.packs;
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.packService.refreshPack.subscribe(() => {
      this.retrievePacks()
    })
    this.retrievePacks();

    //pack
    this.packForm = this._formBuilder.group({
      title: ['', [Validators.required]],
      price: ['', [Validators.required]],
      bySubject: ['', [Validators.required]],
    });
  }

  modalOpen(modalForm, pack) {
    this.currentPack = null
    this.packForm.reset()
    this.modalService.open(modalForm);
    if (pack) {
      this.currentPack = pack
      this.packForm.patchValue({
        title: pack.title,
        price: pack.price,
        bySubject: pack.bySubject ? 'true':'false'
      });
    }
  }

  filterPacks(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.tempPackData.filter(function (d) {
      if (d.title.toLowerCase().includes(val) || d.price == val) {
        return d
      }
    });
    this.packRows = temp;
  }

  confirmDeletePack(pack) {
    Swal.fire({
      title: `Vous êtes sûr de vouloir supprimer ${pack.title}?`,
      text: 'Vous ne serez pas en mesure de la récupérer!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Oui',
      cancelButtonText: 'Annuler',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(res => this.deletePack(res, pack.id));
  }
}
