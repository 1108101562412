<div class="card">
    <div
        class="card-header d-flex flex-sm-row flex-column justify-content-md-between align-items-start justify-content-start">
        <div>
            <h4 class="card-title mb-25">Distribution des clients selon la 1er joignibilité</h4>
        </div>
        <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
            <span class="badge badge-light-primary" *ngIf="byReachabilityData && byReachabilityData.total">{{byReachabilityData.total}} clients</span>
            <!-- <ng2-flatpickr [config]="DateRangeOptions" name="DateRange" [(ngModel)]="period"
                (ngModelChange)="retrieveByReachability()" class="mr-1"></ng2-flatpickr> -->
        </div>
    </div>
    <div class="card-body" *ngIf="byReachabilityData">
        <div id="radialbar-chart" #byReachabilityRef>
            <apx-chart [series]="byReachability.series" [chart]="byReachability.chart"
                [plotOptions]="byReachability.plotOptions" [labels]="byReachability.labels"
                [colors]="byReachability.colors" [stroke]="byReachability.stroke"
                [legend]="byReachability.legend"></apx-chart>
        </div>
    </div>
</div>