import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TimeFrameService {

  private _refreshTimeFrame = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshTimeFrame() {
    return this._refreshTimeFrame;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/api/time_frame/list`);
  }
  getMinAndMax() {
    return this._httpClient.get(`${environment.apiUrl}/api/time_frame/min_and_max`);
  }

  create(title, start_time, end_time) {
    return this._httpClient
      .post(`${environment.apiUrl}/api/time_frame/add`, { title, start_time, end_time })
      .pipe(
        tap(() => {
          this._refreshTimeFrame.next()
        })
      );
  }

  update(id, title, start_time, end_time) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/time_frame/${id}/edit`, { title, start_time, end_time })
      .pipe(
        tap(() => {
          this._refreshTimeFrame.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/api/time_frame/${id}/delete`)
      .pipe(
        tap(() => {
          this._refreshTimeFrame.next()
        })
      );
  }

}
