import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'app/auth/service';

import { ContactService } from 'app/service/contact/contact.service';
import { CustomerService } from 'app/service/customer/customer.service';

// Interface
interface notification {
  messages: [];
  systemMessages: [];
  system: Boolean;
}

@Component({
  selector: 'app-navbar-notification',
  templateUrl: './navbar-notification.component.html'
})
export class NavbarNotificationComponent implements OnInit {
  // Public
  public notifications: notification;
  public alerts

  /**
   *
   * @param {NotificationsService} _notificationsService
   */
  constructor(
    private _authenticationService: AuthenticationService,
    private customerService: CustomerService,
    private contactService: ContactService
  ) {}

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  retrieveCustomers(){
    this.customerService.numberOfAlertCustomers('mine')
    .subscribe({
      next: (data) => {
        this.alerts = data;
      },
      error: (e) => console.error(e)
    });
  }

  /**
   * On init
   */
  ngOnInit(): void {
    if(this._authenticationService.currentUserValue){
      this.customerService.refreshCustomer.subscribe(() => {
        this.retrieveCustomers()
      })
      this.contactService.refreshContact.subscribe(() => {
        this.retrieveCustomers()
      })
      this.retrieveCustomers();
    }
  }
}