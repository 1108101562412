import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private _refreshCustomer = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshCustomer() {
    return this._refreshCustomer;
  }

  getStatut(customer){
    var statutText = '';
    var statutColor = '';
    switch (customer.statut){
      case 0: { statutText = customer.first_contact != null ? 'Injoignable':'Pas encore contacté'; statutColor = 'secondary'; break ; }
      case 1: { statutText = 'Converti'; statutColor = 'primary'; break ; }
      case 2: { statutText = 'Centre envoyé'; statutColor = 'warning'; break ; }
      case 3: { statutText = 'RIB envoyé'; statutColor = 'success'; break ; }
      case 4: { statutText = 'Réfléchi'; statutColor = 'warning'; break ; }
      case 5: { statutText = `Séance gratuite <br> ${customer.last_free_session != null ? customer.last_free_session.date:''}`; statutColor = 'warning'; break ; }
      case 6: { statutText = 'Refus'; statutColor = 'danger'; break ; }
      case 7: { statutText = 'Hors cible'; statutColor = 'danger'; break ; }
      case 8: { statutText = 'Faux numéro'; statutColor = 'danger'; break ; }
      case 9: { statutText = 'A rappeler'; statutColor = 'warning'; break ; }
      case 10: { statutText = 'Abonnement gratuit'; statutColor = 'warning'; break ; }
    }
    return { text: statutText, color: statutColor };
  }

  numberOfAlertCustomers(belonging) {
    return this._httpClient.get(`${environment.apiUrl}/api/customer/number_of_alert_customers/${belonging}`);
  }
  getParams() {
    return this._httpClient.get(`${environment.apiUrl}/api/customer/params`);
  }
  unreachable(time_frame, belonging) {
    return this._httpClient.get(`${environment.apiUrl}/api/customer/${time_frame}/unreachable_to_call/${belonging}`);
  }
  reachableToCall(belonging) {
    return this._httpClient.get(`${environment.apiUrl}/api/customer/reachable_to_call/${belonging}`);
  }
  withCallbackAppointment(period, belonging) {
    return this._httpClient.get(`${environment.apiUrl}/api/customer/${period}/call_back_appointment/${belonging}`);
  }
  sentToCenter(state) {
    return this._httpClient.get(`${environment.apiUrl}/api/customer/${state}/sent_to_center`);
  }
  active(belonging) {
    return this._httpClient.get(`${environment.apiUrl}/api/active_customer/${belonging}/list`);
  }
  warm() {
    return this._httpClient.get(`${environment.apiUrl}/api/customer/list_warm`);
  }
  withoutContact() {
    return this._httpClient.get(`${environment.apiUrl}/api/customer/without_contact`);
  }
  fromPlatform() {
    return this._httpClient.get(`${environment.apiUrl}/api/customer/list_from_platform`);
  }

  get(id) {
    return this._httpClient.get(`${environment.apiUrl}/api/customer/${id}/detail`);
  }
  checkIfExist(phone) {
    return this._httpClient.get(`${environment.apiUrl}/api/customer/${phone}/exist`);
  }
  getByPhone(phone) {
    return this._httpClient.get(`${environment.apiUrl}/api/customer/${phone}/by_phone`);
  }

  create(phone, parent_phone, email, first_name, parents_first_name, last_name, description, gender, governorate, address, contact_channel_object, campaign, level, establishment, heard_about_us, warm,//customer
    createContact, date, start_time, duration, type, reachability, //contact
    comment, statut, payment_method, center, reflection_reason, reason_for_rejection, customer_will_call, date_of_callback_appointment  //history
  ) {
    return this._httpClient
      .post(`${environment.apiUrl}/api/customer/add`, {
        phone, parent_phone, email, first_name, parents_first_name, last_name, description, gender, governorate, address, contact_channel_object, campaign, level, establishment, heard_about_us, warm,//customer
        createContact, date, start_time, duration, type, reachability,
        comment, statut, payment_method, center, reflection_reason, reason_for_rejection, customer_will_call, date_of_callback_appointment 
      })
      .pipe(
        tap(() => {
          this._refreshCustomer.next()
        })
      );
  }

  createWithSubscription(phone, parent_phone, email, first_name, parents_first_name, last_name, description, gender, governorate, address, contact_channel_object, campaign, level, establishment, heard_about_us, warm,
    createContact, date, start_time, duration, type, reachability, //contact
    comment, statut, payment_method, center, reflection_reason, reason_for_rejection,//history
    pack, bySubject, subjects, subscription_duration, start_date, end_date, price, payment_date, free //subscription
  ) {
    return this._httpClient
      .post(`${environment.apiUrl}/api/customer/add`, {
        phone, parent_phone, email, first_name, parents_first_name, last_name, description, gender, governorate, address, contact_channel_object, campaign, level, establishment, heard_about_us, warm, //customer
        createContact, date, start_time, duration, type, reachability,
        comment, statut, payment_method, center, reflection_reason, reason_for_rejection,
        pack, bySubject, subjects, subscription_duration, start_date, end_date, price, payment_date, free
      })
      .pipe(
        tap(() => {
          this._refreshCustomer.next()
        })
      );
  }

  createWithFreeSession(phone, parent_phone, email, first_name, parents_first_name, last_name, description, gender, governorate, address, contact_channel_object, campaign, level, establishment, heard_about_us, warm, //customer
    createContact, date, start_time, duration, type, reachability, //contact
    comment, statut, payment_method, center, reflection_reason, reason_for_rejection, //history
    subject, free_session_date //free session

  ) {
    return this._httpClient
      .post(`${environment.apiUrl}/api/customer/add`, {
        phone, parent_phone, email, first_name, parents_first_name, last_name, description, gender, governorate, address, contact_channel_object, campaign, level, establishment, heard_about_us, warm, //customer
        createContact, date, start_time, duration, type, reachability,
        comment, statut, payment_method, center, reflection_reason, reason_for_rejection,
        subject, free_session_date
      })
      .pipe(
        tap(() => {
          this._refreshCustomer.next()
        })
      );
  }

  filter(statut, contact_channel, level, start_date_of_contact, end_date_of_contact, belonging) {
    return this._httpClient
      .post(`${environment.apiUrl}/api/customer/filter`, { statut, contact_channel, level, start_date_of_contact, end_date_of_contact, belonging });
  }

  updateStatut(id, statut, payement_method, center, reflection_reason,  reason_for_rejection, customer_will_call, date_of_callback_appointment , comment) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/customer/${id}/statut`, { statut, payement_method, center, reflection_reason, reason_for_rejection, customer_will_call, date_of_callback_appointment , comment })
      .pipe(
        tap(() => {
          this._refreshCustomer.next()
        })
      );
  }

  updateReachability(id, reachability) {
    return this._httpClient
      .get(`${environment.apiUrl}/api/customer/${id}/reachability/${reachability}`)
      .pipe(
        tap(() => {
          this._refreshCustomer.next()
        })
      );
  }

  update(id, phone, parent_phone, email, first_name, parents_first_name, last_name, description, gender, governorate, address, contact_channel, campaign, level, establishment, heard_about_us
  ) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/customer/${id}/edit`, { phone, parent_phone, email, first_name, parents_first_name, last_name, description, gender, governorate, address, contact_channel, campaign, level, establishment, heard_about_us })
      .pipe(
        tap(() => {
          this._refreshCustomer.next()
        })
      );
  }

  updateCallBackAppointment(id, date) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/customer/${id}/edit/call_back_appointment/${date}`, {})
      .pipe(
        tap(() => {
          this._refreshCustomer.next()
        })
      );
  }

  updateProlongation(id, prolongation_days) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/customer/${id}/edit/prolongation_days/${prolongation_days}`, {})
      .pipe(
        tap(() => {
          this._refreshCustomer.next()
        })
      );
  }

  updateWarm(id) {
    return this._httpClient
      .get(`${environment.apiUrl}/api/customer/${id}/warm`)
      .pipe(
        tap(() => {
          this._refreshCustomer.next()
        })
      );
  }

  sendSms(customers, content) {
    return this._httpClient
      .post(`${environment.apiUrl}/api/sms_history/add`, { customers, content })
      .pipe(
        tap(() => {
          this._refreshCustomer.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/api/customer/${id}/delete`)
      .pipe(
        tap(() => {
          this._refreshCustomer.next()
        })
      );
  }

}
