import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FlatpickrOptions } from 'ng2-flatpickr';

import Swal from 'sweetalert2';

import { CampaignService } from 'app/service/campaign/campaign.service';
import { PackService } from 'app/service/pack/pack.service';
import { DatePipe } from '@angular/common';
import { ToastService } from 'app/service/toast/toast.service';
import { AuthenticationService } from 'app/auth/service';
import { ContactChannelService } from 'app/service/contact-channel/contact-channel.service';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CampaignComponent implements OnInit {
  public loadedData = false

  public campaigns;
  public currentCampaign
  private tempCampaignData = [];
  public campaignRows: any;
  public exportCSVData

  public ColumnMode = ColumnMode;
  public basicSelectedOption: number = 25;
  public SelectionType = SelectionType;
  public selected = [];
  public chkBoxSelected = [];

  public period

  public packs;
  public contactChannels

  public campaignForm: FormGroup;
  public campaignSubmitted = false
  public loadingSubmitCampaign = false

  public filterForm: FormGroup;
  public filterSubmitted = false
  public loadingSubmitFilter = false

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    private _authenticationService: AuthenticationService,
    private campaignService: CampaignService,
    private packService: PackService,
    private contactChannelService: ContactChannelService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    public datepipe: DatePipe
  ) { }

  // convenience getter for easy access to campaign form fields
  get c() {
    return this.campaignForm.controls;
  }
  get f() {
    return this.filterForm.controls;
  }

  public filterRange = {
    altInput: true,
    enableTime: false,
    defaultDate: [],
    altFormat: "d/m/Y",
    mode: 'range',
  };

  public DateRangeOptions: FlatpickrOptions = {
    altInput: true,
    enableTime: false,
    altFormat: "d/m/Y",
    mode: 'range',
  };

  deleteCampaign(res, id) {
    if (res.value == true) {
      this.campaignService.delete(id)
        .subscribe({
          next: (response) => {
            this.toastService.error('Campagne supprimée', 'Campagne supprimée avec succès')
          },
          error: (e) => console.error(e)
        });
    }
  }
  updateStatut(res, campaign) {
    if (res.value == true) {
      this.campaignService.updateStatut(campaign.id, !campaign.statut)
        .subscribe({
          next: (response) => {
          },
          error: (e) => console.error(e)
        });
    }
  }
  updateCampaign() {
    let startDate = this.datepipe.transform(this.c.date.value[0], 'yyyy/MM/dd')
    let endDate = this.datepipe.transform(this.c.date.value[1], 'yyyy/MM/dd')
    this.campaignService.update(this.currentCampaign.id, this.c.reference.value, this.c.budget.value, startDate, endDate, this.c.pack.value, this.c.channels.value)
      .subscribe({
        next: (response) => {
          this.campaignSubmitted = false
          this.loadingSubmitCampaign = false
          this.modalService.dismissAll()
          this.toastService.info('Campagne mise à jour', 'Campagne mise à jour avec succès')
          this.campaignForm.reset()
        },
        error: (e) => {
          this.loadingSubmitCampaign = false
          this.campaignSubmitted = false
          console.error(e)
        }
      });
  }
  createCampaign() {
    this.campaignSubmitted = true
    if (this.campaignForm.invalid) {
      return
    } else {
      this.loadingSubmitCampaign = true
      let startDate = this.datepipe.transform(this.c.date.value[0], 'yyyy/MM/dd')
      let endDate = this.datepipe.transform(this.c.date.value[1], 'yyyy/MM/dd')
      this.campaignService.create(this.c.reference.value, this.c.budget.value, startDate, endDate, this.c.pack.value, this.c.channels.value)
        .subscribe({
          next: (response) => {
            this.campaignSubmitted = false
            this.loadingSubmitCampaign = false
            this.modalService.dismissAll()
            this.toastService.success('Campagne créée', 'Campagne créée avec succès')
            this.campaignForm.reset()
          },
          error: (e) => {
            this.loadingSubmitCampaign = false
            this.campaignSubmitted = false
            console.error(e)
          }
        });
    }
  }
  submitCampaign() {
    if (this.currentCampaign) {
      this.updateCampaign()
    } else {
      this.createCampaign()
    }
  }

  retrieveContactChannels() {
    this.contactChannelService.getAll()
      .subscribe({
        next: (data) => {
          this.contactChannels = data;
        },
        error: (e) => console.error(e)
      });
  }

  retrievePacks(): void {
    this.packService.getAll()
      .subscribe({
        next: (data) => {
          this.packs = data;
        },
        error: (e) => console.error(e)
      });
  }

  retrieveCampaigns(): void {
    this.campaignService.getAll()
      .subscribe({
        next: (data) => {
          this.campaigns = data;
          this.loadedData = true
          this.tempCampaignData = this.campaigns;
          this.campaignRows = this.campaigns;
          this.exportCSVData = this.campaignRows.map((item) => {
            return ({ Référence: item.reference, Budget: item.budget, Date: 'De ' + item.start_date + ' à ' + item.end_date, Packs: item.packs.map(pack => pack.title), Canaux: item.channels, NProspects: item.customers_number, statut: item.statut ? 'Active' : 'Inactive' });
          });
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.campaignService.refreshCampaign.subscribe(() => {
      this.retrieveCampaigns()
    })
    this.retrieveCampaigns();

    //filter
    this.filterForm = this._formBuilder.group({
      statut: [null], date: ['']
    });

    var today = new Date()
    var end = new Date ()
    end.setDate(end.getDate() - 90);
    this.filterRange.defaultDate = [end, today]
    this.filterForm.get('date').setValue([new Date(end), today])
    this.submitFilter()
    this.retrievePacks()
    this.retrieveContactChannels()

    //campaign
    this.campaignForm = this._formBuilder.group({
      reference: ['', [Validators.required]], budget: ['', [Validators.required]],
      date: ['', [Validators.required]],
      pack: ['', [Validators.required]],
      channels: ['', [Validators.required]],
    });
  }

  checkAccess(action) {
    var user = this._authenticationService.currentUserValue
    var page = user.access.find(item => item.pageTitle == 'Campagne')
    if (page != null && (page.pageActions.includes('all') || page.pageActions.includes(action))) {
      return true
    } else {
      return false
    }
  }

  submitFilter() {
    this.loadedData = false
    this.period = null
    var startDate = null; var endDate = null;
    if (this.f.date.value != null && this.f.date.value[0] != null) {
      startDate = this.datepipe.transform(this.f.date.value[0], 'yyyy/MM/dd')
      endDate = this.datepipe.transform(this.f.date.value[1], 'yyyy/MM/dd')
    }
    this.campaignService.filter(this.f.statut.value, startDate, endDate)
      .subscribe({
        next: (data) => {
          this.campaigns = data;
          this.loadedData = true
          this.period = [startDate, endDate]
          this.tempCampaignData = this.campaigns;
          this.campaignRows = this.campaigns;
          this.exportCSVData = this.campaignRows.map((item) => {
            return ({ Référence: item.reference, Budget: item.budget, Date: 'De ' + item.start_date + ' à ' + item.end_date, Packs: item.packs.map(pack => pack.title), Canaux: item.channels, NProspects: item.customers_number, statut: item.statut ? 'Active' : 'Inactive' });
          });
        },
        error: (e) => {
          this.loadingSubmitFilter = false
          this.filterSubmitted = false
          console.error(e)
        }
      });
  }

  modalOpen(modalForm, campaign) {
    this.currentCampaign = null
    this.campaignForm.reset()
    this.modalService.open(modalForm, {
      centered: true,
      size: 'lg'
    });
    if (campaign) {
      this.currentCampaign = campaign
      this.campaignForm.patchValue({
        reference: campaign.reference,
        budget: campaign.budget,
        date: [campaign.start_date, campaign.end_date],
        pack: campaign.packs.map(pack => pack.id),
        channels: campaign.contact_channels.map(channel => channel.id),
      });
    }
  }

  filterCampaigns(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.tempCampaignData.filter(function (d) {
      var packs = d.packs.map(item => item.title.toLowerCase())
      if (d.reference.toLowerCase().includes(val) || d.budget == val || d.start_date.toLowerCase().includes(val) || d.end_date.toLowerCase().includes(val) || packs.includes(val)) {
        return d
      }
    });
    this.campaignRows = temp;
    this.exportCSVData = this.campaignRows.map((item) => {
      return ({ Référence: item.reference, Budget: item.budget, Date: 'De ' + item.start_date + ' à ' + item.end_date, Packs: item.packs.map(pack => pack.title), Canaux: item.channels, NProspects: item.customers_number, statut: item.statut ? 'Active' : 'Inactive' });
    });
  }

  confirmUpdateStatus(campaign) {
    Swal.fire({
      title: `${campaign.statut == true ? "Désactiver" : "Activer"} #${campaign.reference}`,
      text: `Vous êtes sûr de vouloir ${campaign.statut == true ? "Désactiver" : "Activer"} #${campaign.reference}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Oui',
      cancelButtonText: 'Annuler',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(res => this.updateStatut(res, campaign));
  }
  confirmDeleteCampaign(campaign) {
    Swal.fire({
      title: `Vous êtes sûr de vouloir supprimer #${campaign.reference}?`,
      text: 'Vous ne serez pas en mesure de la récupérer!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Oui',
      cancelButtonText: 'Annuler',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(res => this.deleteCampaign(res, campaign.id));
  }
}
