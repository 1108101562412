import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { CustomerStatusHistoryService } from 'app/service/customer-status-history/customer-status-history.service';


@Component({
  selector: 'app-seller-history',
  templateUrl: './seller-history.component.html',
  styleUrls: ['./seller-history.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SellerHistoryComponent implements OnInit {

  public loadedData = false

  public seller_id
  public global_period
  public period

  public data

  public historys;
  public currentHistory
  private tempHistoryData = [];
  public historyRows: any;
  public exportCSVData

  public ColumnMode = ColumnMode;
  public basicSelectedOption: number = 25;
  public SelectionType = SelectionType;
  public selected = [];
  public chkBoxSelected = [];

  public searchForm: FormGroup;

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(private historyService: CustomerStatusHistoryService,
    private route: ActivatedRoute,
    private datepipe: DatePipe,
    private _formBuilder: FormBuilder,
  ) {
  }
  public globalDateOptions = {
    altInput: true,
    mode: 'range',
    defaultDate: [],
    maxDate: null,
    altFormat: 'Y-n-j',
  };

  retrieveHistorys(): void {
    this.loadedData = false
    var startDate = this.datepipe.transform(this.period[0], 'yyyy-MM-dd')
    var endDate = startDate
    if (this.period[1] != null) {
      endDate = this.datepipe.transform(this.period[1], 'yyyy-MM-dd')
    }
    this.historyService.getByUser(this.seller_id, startDate, endDate, this.searchForm.get('contact_statut').value, this.searchForm.get('customer_statut').value)
      .subscribe({
        next: (data) => {
          this.data = data
          this.historys = this.data.data;
          this.loadedData = true
          this.tempHistoryData = this.historys;
          this.historyRows = this.historys;
          this.exportCSVData = this.historyRows.map((item) => {
            return ({ Date: item.created_at });
          });
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.seller_id = this.route.snapshot.paramMap.get('id');

    var start = new Date().setDate((new Date()).getDate() - 7);
    var end = new Date();

    this.globalDateOptions.maxDate = new Date()

    this.period = [start, end]

    //search
    this.searchForm = this._formBuilder.group({
      contact_statut: [null],
      customer_statut: [null],
    });

    this.changePeriod();
  }

  changePeriod() {
    if (this.period[1]) {
      this.retrieveHistorys();

      this.global_period = [this.datepipe.transform(this.period[0], 'yyyy-MM-dd'), this.datepipe.transform(this.period[1], 'yyyy-MM-dd')]
      this.globalDateOptions.defaultDate = this.global_period
    }
  }

  filterHistorys(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.tempHistoryData.filter(function (d) {
      if ((d.phone != null && d.phone.toLowerCase().includes(val)) || (d.first_name != null && d.first_name.toLowerCase().includes(val)) || (d.last_name != null && d.last_name.includes(val))) {
        return d
      }
    });
    this.historyRows = temp;
    this.exportCSVData = this.historyRows
  }
}
