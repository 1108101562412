import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { StatisticService } from 'app/service/statistic/statistic.service';
import { FlatpickrOptions } from 'ng2-flatpickr';

@Component({
  selector: 'app-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NumberComponent implements OnChanges {

  @Input() period;
  @Input() disable_period;
  @Input() campaign;
  @Input() seller;

  public data

  constructor(private statisticService: StatisticService, private datepipe: DatePipe) { }

  public DateRangeOptions: FlatpickrOptions = {
    altInput: true,
    mode: 'range',
    altFormat: 'Y-n-j',
  };

  retrieveData() {
    var startDate = this.datepipe.transform(this.period[0], 'yyyy-MM-dd')
    var endDate = startDate
    if (this.period[1] != null) {
      endDate = this.datepipe.transform(this.period[1], 'yyyy-MM-dd')
    }
    this.statisticService.countBy(startDate, endDate, this.campaign, this.seller)
      .subscribe({
        next: (data) => {
          this.data = data
        },
        error: (e) => console.error(e)
      });
  }

  ngOnChanges(): void {
    this.DateRangeOptions.defaultDate = this.period
    if (this.disable_period == true) {
      this.DateRangeOptions.enable = this.period
    }

    this.retrieveData();
  }

}
