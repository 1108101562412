import { Component, Input, OnChanges, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  ApexChart,
  ApexStroke,
  ApexDataLabels,
  ApexXAxis,
  ApexTooltip,
  ApexPlotOptions,
  ApexYAxis,
  ApexFill,
  ApexMarkers,
  ApexNonAxisChartSeries,
  ApexLegend,
  ApexResponsive,
  ApexStates
} from 'ng-apexcharts';

import { StatisticService } from 'app/service/statistic/statistic.service';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { DatePipe } from '@angular/common';

export interface ChartOptions2 {
  // Apex-non-axis-chart-series
  series?: ApexNonAxisChartSeries;
  chart?: ApexChart;
  stroke?: ApexStroke;
  tooltip?: ApexTooltip;
  dataLabels?: ApexDataLabels;
  fill?: ApexFill;
  colors?: string[];
  legend?: ApexLegend;
  labels?: any;
  plotOptions?: ApexPlotOptions;
  responsive?: ApexResponsive[];
  markers?: ApexMarkers[];
  xaxis?: ApexXAxis;
  yaxis?: ApexYAxis;
  states?: ApexStates;
}

@Component({
  selector: 'app-by-reachability',
  templateUrl: './by-reachability.component.html',
  styleUrls: ['./by-reachability.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ByReachabilityComponent implements OnChanges {

  @Input() period;
  @Input() disable_period;
  @Input() campaign;
  @Input() seller;

  @ViewChild('byReachabilityRef') byReachabilityRef: any;
  public byReachability: Partial<ChartOptions2>;

  public byReachabilityData

  constructor(private statisticService: StatisticService, private datepipe: DatePipe) { }

  public DateRangeOptions: FlatpickrOptions = {
    altInput: true,
    mode: 'range',
    altFormat: 'Y-n-j',
  };

  retrieveByReachability() {
    var startDate = this.datepipe.transform(this.period[0], 'yyyy-MM-dd')
    var endDate = startDate
    if (this.period[1] != null) {
      endDate = this.datepipe.transform(this.period[1], 'yyyy-MM-dd')
    }
    this.statisticService.byReachability(startDate, endDate, this.campaign, this.seller)
      .subscribe({
        next: (data) => {
          this.byReachabilityData = data
          var total = this.byReachabilityData.total
          this.byReachability = {
            series: this.byReachabilityData.data,
            labels: this.byReachabilityData.categories,
            chart: {
              height: 450,
              type: 'radialBar'
            },
            colors: ['#ffe700', '#00d4bd', '#826bf8', '#FFA1A1'],
            plotOptions: {
              radialBar: {
                hollow: {
                  size: '25%'
                },
                track: {
                  margin: 15
                },
                dataLabels: {
                  name: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat'
                  },
                  value: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat'
                  },
                  total: {
                    show: true,
                    fontSize: '2rem',
                    label: 'Total',
                    formatter: function (w) {
                      return total + ' client(s)';
                    }
                  }
                }
              }
            },
            legend: {
              show: true,
              position: 'bottom'
            },
            stroke: {
              lineCap: 'round'
            }
          };
        },
        error: (e) => console.error(e)
      });
  }

  ngOnChanges(): void {
    this.DateRangeOptions.defaultDate = this.period
    if (this.disable_period == true) {
      this.DateRangeOptions.enable = this.period
    }

    this.retrieveByReachability();
  }

}
