import { Component, ViewEncapsulation, ViewChild, Input, OnChanges, ChangeDetectorRef } from '@angular/core';
import {
  ApexChart,
  ApexStroke,
  ApexDataLabels,
  ApexTooltip,
  ApexPlotOptions,
  ApexYAxis,
  ApexFill,
  ApexMarkers,
  ApexLegend,
  ApexAxisChartSeries,
  ApexGrid,
  ApexXAxis,
  ApexTitleSubtitle,
  ApexTheme
} from 'ng-apexcharts';

import { StatisticService } from 'app/service/statistic/statistic.service';
import { colors } from 'app/colors.const';
import { DatePipe } from '@angular/common';

// interface ChartOptions
export interface ChartOptions {
  series?: ApexAxisChartSeries;
  chart?: ApexChart;
  xaxis?: ApexXAxis;
  dataLabels?: ApexDataLabels;
  grid?: ApexGrid;
  stroke?: ApexStroke;
  legend?: ApexLegend;
  title?: ApexTitleSubtitle;
  colors?: string[];
  tooltip?: ApexTooltip;
  plotOptions?: ApexPlotOptions;
  yaxis?: ApexYAxis;
  fill?: ApexFill;
  labels?: string[];
  markers: ApexMarkers;
  theme: ApexTheme;
}

@Component({
  selector: 'app-campaign-cost-histogram',
  templateUrl: './campaign-cost-histogram.component.html',
  styleUrls: ['./campaign-cost-histogram.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CampaignCostHistogramComponent implements OnChanges {

  @Input() statut;
  @Input() period;

  @ViewChild('costHistogramRef') costHistogramRef: any;
  public costHistogram: Partial<ChartOptions>;

  public costHistogramData

  constructor(private statisticService: StatisticService, private datepipe: DatePipe, private cd: ChangeDetectorRef){}

  retrieveCostHistogram() {
    var startDate = null; var endDate = null
    if(this.period != null){
       startDate = this.datepipe.transform(this.period[0], 'yyyy-MM-dd')
       endDate = startDate
      if (this.period[1] != null) {
        endDate = this.datepipe.transform(this.period[1], 'yyyy-MM-dd')
      }
    }
    this.statisticService.campaignCostHistogram(this.statut, startDate, endDate)
      .subscribe({
        next: (data) => {
          this.costHistogramData = data
          this.costHistogram = {
            series: this.costHistogramData.series,
            chart: {
              height: 400,
              type: 'bar',
              toolbar: {
                show: false
              }
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: "35%",
                dataLabels: {
                  position: "top" // top, center, bottom
                }
              }
            },
            grid: {
              xaxis: {
                lines: {
                  show: false
                }
              }
            },
            colors: [colors.solid.danger, colors.solid.info],
            dataLabels: {
              enabled: true,
              offsetY: -20,
              style: {
                fontSize: "12px",
                fontFamily: 'Montserrat',
              }
            },
            xaxis: {
              categories: this.costHistogramData.categories
            }
          };
          this.cd.detectChanges();
        },
        error: (e) => console.error(e)
      });
  }

  ngOnChanges(): void {
    this.retrieveCostHistogram();
  }

}
