import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Swal from 'sweetalert2';

import { GovernorateService } from 'app/service/governorate/governorate.service';
import { ToastService } from 'app/service/toast/toast.service';

@Component({
  selector: 'app-governorate',
  templateUrl: './governorate.component.html',
  styleUrls: ['./governorate.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GovernorateComponent implements OnInit {

  public loadedData = false

  public governorates;
  public currentGovernorate
  private tempGovernorateData = [];
  public governorateRows: any;
  public exportCSVData

  public ColumnMode = ColumnMode;
  public basicSelectedOption: number = 25;
  public SelectionType = SelectionType;
  public selected = [];
  public chkBoxSelected = [];

  public governorateForm: FormGroup;
  public governorateSubmitted = false
  public loadingSubmitGovernorate = false

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    private governorateService: GovernorateService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
  ) { }

  // convenience getter for easy access to governorate form fields
  get p() {
    return this.governorateForm.controls;
  }

  deleteGovernorate(res, id) {
    if (res.value == true) {
      this.governorateService.delete(id)
        .subscribe({
          next: (response) => {
            this.toastService.error('Niveau supprimé', 'Niveau supprimé avec succès')
          },
          error: (e) => console.error(e)
        });
    }
  }
  updateGovernorate() {
    this.governorateService.update(this.currentGovernorate.id, this.p.title.value)
      .subscribe({
        next: (response) => {
          this.governorateSubmitted = false
          this.loadingSubmitGovernorate = false
          this.modalService.dismissAll()
          this.toastService.info('Niveau mis à jour', 'Niveau mis à jour avec succès')
          this.governorateForm.reset()
        },
        error: (e) => {
          this.loadingSubmitGovernorate = false
          this.governorateSubmitted = false
          console.error(e)
        }
      });
  }
  createGovernorate() {
    this.governorateSubmitted = true
    if (this.governorateForm.invalid) {
      return
    } else {
      this.loadingSubmitGovernorate = true
      this.governorateService.create(this.p.title.value)
        .subscribe({
          next: (response) => {
            this.governorateSubmitted = false
            this.loadingSubmitGovernorate = false
            this.modalService.dismissAll()
            this.toastService.success('Niveau créé', 'Niveau créé avec succès')
            this.governorateForm.reset()
          },
          error: (e) => {
            this.loadingSubmitGovernorate = false
            this.governorateSubmitted = false
            console.error(e)
          }
        });
    }
  }
  submitGovernorate() {
    if (this.currentGovernorate) {
      this.updateGovernorate()
    } else {
      this.createGovernorate()
    }
  }

  retrieveGovernorates(): void {
    this.governorateService.getAll()
      .subscribe({
        next: (data) => {
          this.governorates = data;
          this.loadedData = true
          this.tempGovernorateData = this.governorates;
          this.governorateRows = this.governorates;
          this.exportCSVData = this.governorates;
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.governorateService.refreshGovernorate.subscribe(() => {
      this.retrieveGovernorates()
    })
    this.retrieveGovernorates();

    //governorate
    this.governorateForm = this._formBuilder.group({
      title: ['', [Validators.required]],
    });
  }

  modalOpen(modalForm, governorate) {
    this.currentGovernorate = null
    this.governorateForm.reset()
    this.modalService.open(modalForm);
    if (governorate) {
      this.currentGovernorate = governorate
      this.governorateForm.patchValue({
        title: governorate.title,
      });
    }
  }

  filterGovernorates(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.tempGovernorateData.filter(function (d) {
      return d.title.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.governorateRows = temp;
  }

  confirmDeleteGovernorate(governorate) {
    Swal.fire({
      title: `Vous êtes sûr de vouloir supprimer ${governorate.title}?`,
      text: 'Vous ne serez pas en mesure de la récupérer!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Oui',
      cancelButtonText: 'Annuler',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(res => this.deleteGovernorate(res, governorate.id));
  }
}
