import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _refreshUser = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshUser() {
    return this._refreshUser;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/api/user/list`);
  }
  getSeller() {
    return this._httpClient.get(`${environment.apiUrl}/api/user/seller_list`);
  }
  getById(id) {
    return this._httpClient.get(`${environment.apiUrl}/api/user/${id}/detail`);
  }

  create(username, role, center, password) {
    return this._httpClient
      .post(`${environment.apiUrl}/api/user/add`, { username, role, center, password })
      .pipe(
        tap(() => {
          this._refreshUser.next()
        })
      );
  }

  update(id, username, role, center) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/user/${id}/edit`, { username, role, center })
      .pipe(
        tap(() => {
          this._refreshUser.next()
        })
      );
  }

  updateUserData(id, username, password) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/user/${id}/edit`, { username, password })
      .pipe(
        tap(() => {
          this._refreshUser.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/api/user/${id}/delete`)
      .pipe(
        tap(() => {
          this._refreshUser.next()
        })
      );
  }

}