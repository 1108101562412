import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { SubjectService } from 'app/service/subject/subject.service';

import { SubscriptionService } from 'app/service/subscription/subscription.service';

@Component({
  selector: 'app-free-subscription',
  templateUrl: './free-subscription.component.html',
  styleUrls: ['./free-subscription.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FreeSubscriptionComponent implements OnInit {

  public loadedData = false
  public loadedDatatable = false

  public freeSubscriptions;
  private tempFreeSubscriptionData = [];
  public freeSubscriptionRows: any;
  public exportCSVData

  public ColumnMode = ColumnMode;
  public basicSelectedOption: number = 25;
  public SelectionType = SelectionType;
  public selected = [];
  public chkBoxSelected = [];

  public currentPeriod = 'future'

  public subjects
  public selectedSubjects = []

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(private SubscriptionService: SubscriptionService, private subjectService: SubjectService) { }

  retrieveSubjects(){
    this.subjectService.getAll()
    .subscribe({
      next: (data) => {
        this.subjects = data;
      },
      error: (e) => console.error(e)
    });
  }

  retrieveFreeSubscriptions(): void {
    this.loadedDatatable = false
    this.SubscriptionService.getByPeriodAndSubjects(this.currentPeriod, this.selectedSubjects)
      .subscribe({
        next: (data) => {
          this.freeSubscriptions = data;
          this.loadedData = true
          this.loadedDatatable = true
          this.tempFreeSubscriptionData = this.freeSubscriptions;
          this.freeSubscriptionRows = this.freeSubscriptions;
          this.exportCSVData = this.freeSubscriptions;
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.SubscriptionService.refreshSubscription.subscribe(() => {
      this.retrieveFreeSubscriptions()
    })
    this.retrieveFreeSubscriptions();
    this.retrieveSubjects();
  }

  changePeriod(period) {
    this.currentPeriod = period
    this.retrieveFreeSubscriptions()
  }

  filterFreeSubscriptions(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.tempFreeSubscriptionData.filter(function (d) {
      if (d.date.toLowerCase().includes(val) || (d.customer.first_name != null && d.customer.first_name.toLowerCase().includes(val)) || (d.customer.last_name != null && d.customer.last_name.toLowerCase().includes(val))) {
        return d
      }
    });
    this.freeSubscriptionRows = temp;
  }
}
