<div class="card">
    <div
        class="card-header d-flex flex-sm-row flex-column justify-content-md-between align-items-start justify-content-start">
        <div>
            <h4 class="card-title mb-25">Taux de clients contactés & convertis</h4>
        </div>
        <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
            <!-- <span class="badge badge-light-primary" *ngIf="contactedAndConvertedData && contactedAndConvertedData.total">{{contactedAndConvertedData.total}} clients</span> -->
            De {{period[0]}} à {{period[1]}}

            <!-- <ng2-flatpickr [config]="DateRangeOptions" name="DateRange" [(ngModel)]="period"
                (ngModelChange)="retrieveByLevel()" class="mr-1"></ng2-flatpickr> -->
        </div>
    </div>
    <div class="card-body" *ngIf="contactedAndConvertedData">
        <div id="bar-chart" #contactedAndConvertedRef>
            <apx-chart [series]="contactedAndConverted.series" [chart]="contactedAndConverted.chart" [dataLabels]="contactedAndConverted.dataLabels"
                [plotOptions]="contactedAndConverted.plotOptions" [xaxis]="contactedAndConverted.xaxis" [colors]="contactedAndConverted.colors"
                [grid]="contactedAndConverted.grid"></apx-chart>
        </div>
    </div>
</div>