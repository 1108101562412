import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private _toastrService: ToastrService) { }

  success(title, text) {
    setTimeout(() => {
      this._toastrService.success(
        text,
        title,
        { positionClass: 'toast-top-right', toastClass: 'toast ngx-toastr', closeButton: true }
      );
    }, 2500);
  }

  warning(title, text) {
    setTimeout(() => {
      this._toastrService.warning(
        text,
        title,
        { positionClass: 'toast-top-right', toastClass: 'toast ngx-toastr', closeButton: true }
      );
    }, 2500);
  }

  info(title, text) {
    setTimeout(() => {
      this._toastrService.info(
        text,
        title,
        { positionClass: 'toast-top-right', toastClass: 'toast ngx-toastr', closeButton: true }
      );
    }, 2500);
  }

  error(title, text) {
    setTimeout(() => {
      this._toastrService.error(
        text,
        title,
        { positionClass: 'toast-top-right', toastClass: 'toast ngx-toastr', closeButton: true }
      );
    }, 2500);
  }
}
