import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';

import { SmsHistoryService } from 'app/service/sms-history/sms-history.service';

@Component({
  selector: 'app-sms-history',
  templateUrl: './sms-history.component.html',
  styleUrls: ['./sms-history.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SmsHistoryComponent implements OnInit {

  public loadedData = false

  public sms_historys;
  public currentSmsHistory
  private tempSmsHistoryData = [];
  public sms_historyRows: any;
  public exportCSVData

  public ColumnMode = ColumnMode;
  public basicSelectedOption: number = 25;
  public SelectionType = SelectionType;
  public selected = [];
  public chkBoxSelected = [];

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    private sms_historyService: SmsHistoryService,
  ) { }

  retrieveSmsHistorys(): void {
    this.sms_historyService.getAll()
      .subscribe({
        next: (data) => {
          this.sms_historys = data;
          this.loadedData = true
          this.tempSmsHistoryData = this.sms_historys;
          this.sms_historyRows = this.sms_historys;
          this.exportCSVData = this.sms_historys;
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.sms_historyService.refreshSmsHistory.subscribe(() => {
      this.retrieveSmsHistorys()
    })
    this.retrieveSmsHistorys();
  }

  filterSmsHistorys(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.tempSmsHistoryData.filter(function (d) {
      if(d.content.toLowerCase().includes(val) || d.customers.includes(val) || d.campaign.includes(val) || d.created_at.includes(val)){
        return d;
      }
    });
    this.sms_historyRows = temp;
  }
}
