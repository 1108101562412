import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Swal from 'sweetalert2';

import { CenterService } from 'app/service/center/center.service';
import { ToastService } from 'app/service/toast/toast.service';
import { AuthenticationService } from 'app/auth/service';

@Component({
  selector: 'app-center',
  templateUrl: './center.component.html',
  styleUrls: ['./center.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CenterComponent implements OnInit {

  public loadedData = false

  public centers;
  public currentCenter
  private tempCenterData = [];
  public centerRows: any;
  public exportCSVData

  public ColumnMode = ColumnMode;
  public basicSelectedOption: number = 25;
  public SelectionType = SelectionType;
  public selected = [];
  public chkBoxSelected = [];

  public centerForm: FormGroup;
  public centerSubmitted = false
  public loadingSubmitCenter = false

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    private _authenticationService: AuthenticationService,
    private centerService: CenterService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
  ) { }

  // convenience getter for easy access to center form fields
  get p() {
    return this.centerForm.controls;
  }

  deleteCenter(res, id) {
    if (res.value == true) {
      this.centerService.delete(id)
        .subscribe({
          next: (response) => {
            this.toastService.error('Niveau supprimé', 'Niveau supprimé avec succès')
          },
          error: (e) => console.error(e)
        });
    }
  }
  updateStatut(res, center) {
    if (res.value == true) {
      this.centerService.updateStatut(center.id, !center.statut)
        .subscribe({
          next: (response) => {
          },
          error: (e) => console.error(e)
        });
    }
  }
  updateCenter() {
    this.centerService.update(this.currentCenter.id, this.p.title.value)
      .subscribe({
        next: (response) => {
          this.centerSubmitted = false
          this.loadingSubmitCenter = false
          this.modalService.dismissAll()
          this.toastService.info('Niveau mis à jour', 'Niveau mis à jour avec succès')
          this.centerForm.reset()
        },
        error: (e) => {
          this.loadingSubmitCenter = false
          this.centerSubmitted = false
          console.error(e)
        }
      });
  }
  createCenter() {
    this.centerSubmitted = true
    if (this.centerForm.invalid) {
      return
    } else {
      this.loadingSubmitCenter = true
      this.centerService.create(this.p.title.value)
        .subscribe({
          next: (response) => {
            this.centerSubmitted = false
            this.loadingSubmitCenter = false
            this.modalService.dismissAll()
            this.toastService.success('Niveau créé', 'Niveau créé avec succès')
            this.centerForm.reset()
          },
          error: (e) => {
            this.loadingSubmitCenter = false
            this.centerSubmitted = false
            console.error(e)
          }
        });
    }
  }
  submitCenter() {
    if (this.currentCenter) {
      this.updateCenter()
    } else {
      this.createCenter()
    }
  }

  retrieveCenters(): void {
    this.centerService.getAll()
      .subscribe({
        next: (data) => {
          this.centers = data;
          this.loadedData = true
          this.tempCenterData = this.centers;
          this.centerRows = this.centers;
          this.exportCSVData = this.centers;
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.centerService.refreshCenter.subscribe(() => {
      this.retrieveCenters()
    })
    this.retrieveCenters();

    //center
    this.centerForm = this._formBuilder.group({
      title: ['', [Validators.required]],
    });
  }

  checkAccess(action) {
    var user = this._authenticationService.currentUserValue
    var page = user.access.find(item => item.pageTitle == 'Centre')
    if (page != null && (page.pageActions.includes('all') || page.pageActions.includes(action))) {
      return true
    } else {
      return false
    }
  }

  modalOpen(modalForm, center) {
    this.currentCenter = null
    this.centerForm.reset()
    this.modalService.open(modalForm);
    if (center) {
      this.currentCenter = center
      this.centerForm.patchValue({
        title: center.title,
        price: center.price,
      });
    }
  }

  filterCenters(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.tempCenterData.filter(function (d) {
      return d.title.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.centerRows = temp;
  }

  confirmUpdateStatus(center) {
    Swal.fire({
      title: `${center.statut == true ? "Désactiver" : "Activer"} ${center.title}`,
      text: `Vous êtes sûr de vouloir ${center.statut == true ? "Désactiver" : "Activer"} ${center.title}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Oui',
      cancelButtonText: 'Annuler',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(res => this.updateStatut(res, center));
  }
  confirmDeleteCenter(center) {
    Swal.fire({
      title: `Vous êtes sûr de vouloir supprimer ${center.title}?`,
      text: 'Vous ne serez pas en mesure de la récupérer!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Oui',
      cancelButtonText: 'Annuler',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(res => this.deleteCenter(res, center.id));
  }
}
