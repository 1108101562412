import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatisticService {

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }


  countBy(start_date, end_date, campaign, seller) {
    return this._httpClient.post(`${environment.apiUrl}/api/statistics/count_by`, { start_date, end_date, campaign, seller });
  }

  salesFigures(start_date, end_date, seller) {
    return this._httpClient.post(`${environment.apiUrl}/api/statistics/sales_figures`, { start_date, end_date, seller });
  }

  campaignSales(campaign) {
    return this._httpClient.get(`${environment.apiUrl}/api/statistics/campaign/${campaign}/cost`);
  }
  campaignCostHistogram(statut, start_date, end_date) {
    return this._httpClient.post(`${environment.apiUrl}/api/statistics/campaign/histogram_cost`, { statut, start_date, end_date });
  }

  histogram(start_date, end_date) {
    return this._httpClient.post(`${environment.apiUrl}/api/statistics/histogram_calltime_reachability`, { start_date, end_date });
  }

  callDurationHistogram(start_date, end_date, seller) {
    return this._httpClient.post(`${environment.apiUrl}/api/statistics/call_duration_histogram`, { start_date, end_date, seller });
  }

  convertedCustomersRate(start_date, end_date, campaign, seller) {
    return this._httpClient.post(`${environment.apiUrl}/api/statistics/customer/conversion_rate_per_period`, { start_date, end_date, campaign, seller });
  }
  contactedAndConvertedCustomers(start_date, end_date, campaign, seller) {
    return this._httpClient.post(`${environment.apiUrl}/api/statistics/customer/contacted_and_converted`, { start_date, end_date, campaign, seller });
  }
  reachableCustomersRate(start_date, end_date, campaign, seller) {
    return this._httpClient.post(`${environment.apiUrl}/api/statistics/customer/reachability_rate_per_period`, { start_date, end_date, campaign, seller });
  }

  contactType(start_date, end_date, campaign, seller) {
    return this._httpClient.post(`${environment.apiUrl}/api/statistics/contact/by_type`, { start_date, end_date, campaign, seller });
  }

  byGender(start_date, end_date, campaign, seller) {
    return this._httpClient.post(`${environment.apiUrl}/api/statistics/by_gender`, { start_date, end_date, campaign, seller });
  }
  byGovernorate(start_date, end_date, campaign, seller) {
    return this._httpClient.post(`${environment.apiUrl}/api/statistics/by_governorate`, { start_date, end_date, campaign, seller });
  }
  byChannel(start_date, end_date, campaign, seller) {
    return this._httpClient.post(`${environment.apiUrl}/api/statistics/by_contact_channel`, { start_date, end_date, campaign, seller });
  }
  byLevel(start_date, end_date, campaign, seller) {
    return this._httpClient.post(`${environment.apiUrl}/api/statistics/by_level`, { start_date, end_date, campaign, seller });
  }
  byReachability(start_date, end_date, campaign, seller) {
    return this._httpClient.post(`${environment.apiUrl}/api/statistics/by_reachability`, { start_date, end_date, campaign, seller });
  }
}
