<!-- Statistics Card -->
<div class="card card-statistics" *ngIf="data">
    <div class="card-header">
        <h4 class="card-title">Générales</h4>
        <div class="card-action"> De {{period[0]}} à {{period[1]}}
        </div>
    </div>
    <div class="card-body statistics-body">
        <div class="row justify-content-center">
            <div class="col-sm-4 col-xl-2 mb-2 mb-xl-0">
                <div class="media">
                    <div class="avatar bg-light-primary mr-2">
                        <div class="avatar-content">
                            <i data-feather="users" class="avatar-icon"></i>
                        </div>
                    </div>
                    <div class="media-body my-auto">
                        <h4 class="font-weight-bolder mb-0">{{data.customers}}</h4>
                        <p class="card-text font-small-3 mb-0">Créés</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 col-xl-2 mb-2 mb-xl-0">
                <div class="media">
                    <div class="avatar bg-light-warning mr-2">
                        <div class="avatar-content">
                            <i data-feather="phone-call" class="avatar-icon"></i>
                        </div>
                    </div>
                    <div class="media-body my-auto">
                        <h4 class="font-weight-bolder mb-0">{{data.contacted}}</h4>
                        <p class="card-text font-small-3 mb-0">Contactés</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 col-xl-2 mb-2 mb-sm-0">
                <div class="media">
                    <div class="avatar bg-light-danger mr-2">
                        <div class="avatar-content">
                            <i data-feather="x" class="avatar-icon"></i>
                        </div>
                    </div>
                    <div class="media-body my-auto">
                        <h4 class="font-weight-bolder mb-0">{{data.unreachable}}</h4>
                        <p class="card-text font-small-3 mb-0">Injoignables</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 col-xl-2 mb-2 mb-xl-0">
                <div class="media">
                    <div class="avatar bg-light-success mr-2">
                        <div class="avatar-content">
                            <i data-feather="check" class="avatar-icon"></i>
                        </div>
                    </div>
                    <div class="media-body my-auto">
                        <h4 class="font-weight-bolder mb-0">{{data.reachable}}</h4>
                        <p class="card-text font-small-3 mb-0">Joignables</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 col-xl-2">
                <div class="media">
                    <div class="avatar bg-light-info mr-2">
                        <div class="avatar-content">
                            <i data-feather="thumbs-up" class="avatar-icon"></i>
                        </div>
                    </div>
                    <div class="media-body my-auto">
                        <h4 class="font-weight-bolder mb-0">{{data.converted}}</h4>
                        <p class="card-text font-small-3 mb-0">Convertis</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 col-xl-2">
                <div class="media">
                    <div class="avatar bg-light-primary mr-2">
                        <div class="avatar-content">
                            <i data-feather="clock" class="avatar-icon"></i>
                        </div>
                    </div>
                    <div class="media-body my-auto">
                        <h4 class="font-weight-bolder mb-0">{{data.duration}}</h4>
                        <p class="card-text font-small-3 mb-0">Durée</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--/ Statistics Card -->