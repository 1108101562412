import { Component, Input, OnChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexStroke,
  ApexDataLabels,
  ApexXAxis,
  ApexGrid,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexPlotOptions,
  ApexYAxis,
  ApexFill,
  ApexMarkers,
  ApexTheme,
  ApexLegend,
} from 'ng-apexcharts';

import { colors } from 'app/colors.const';
import { StatisticService } from 'app/service/statistic/statistic.service';
import { DatePipe } from '@angular/common';
import { FlatpickrOptions } from 'ng2-flatpickr';

// interface ChartOptions
export interface ChartOptions {
  series?: ApexAxisChartSeries;
  chart?: ApexChart;
  xaxis?: ApexXAxis;
  dataLabels?: ApexDataLabels;
  grid?: ApexGrid;
  stroke?: ApexStroke;
  legend?: ApexLegend;
  title?: ApexTitleSubtitle;
  colors?: string[];
  tooltip?: ApexTooltip;
  plotOptions?: ApexPlotOptions;
  yaxis?: ApexYAxis;
  fill?: ApexFill;
  labels?: string[];
  markers: ApexMarkers;
  theme: ApexTheme;
}

@Component({
  selector: 'app-customer-reachability',
  templateUrl: './customer-reachability.component.html',
  styleUrls: ['./customer-reachability.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomerReachabilityComponent implements OnChanges {

  @Input() period;
  @Input() disable_period;
  @Input() campaign;
  @Input() seller;

  public rateData

  @ViewChild('reachableCustomersRateRef') reachableCustomersRateRef: any;
  public reachableCustomersRate: Partial<ChartOptions>;

  constructor(private statisticService: StatisticService, private datepipe: DatePipe) { }

  public DateRangeOptions: FlatpickrOptions = {
    altInput: true,
    mode: 'range',
    altFormat: 'Y-n-j',
  };

  retrieveData() {
    var startDate = this.datepipe.transform(this.period[0], 'yyyy-MM-dd')
    var endDate = startDate
    if (this.period[1] != null) {
      endDate = this.datepipe.transform(this.period[1], 'yyyy-MM-dd')
    }
    this.statisticService.reachableCustomersRate(startDate, endDate, this.campaign, this.seller)
      .subscribe({
        next: (data) => {
          this.rateData = data
          this.reachableCustomersRate = {
            series: [
              {
                data: this.rateData.data
              }
            ],
            chart: {
              height: 400,
              type: 'line',
              zoom: {
                enabled: false
              },
              toolbar: {
                show: false
              }
            },
            grid: {
              xaxis: {
                lines: {
                  show: true
                }
              }
            },
            markers: {
              strokeWidth: 7,
              strokeOpacity: 1,
              strokeColors: [],
              colors: [colors.solid.warning]
            },
            colors: [colors.solid.warning],
            dataLabels: {
              enabled: true,
              style: {
                fontSize: "12px",
                fontFamily: 'Montserrat',
                colors: ['#ffffff']
              }
            },
            stroke: {
              curve: 'straight'
            },
            xaxis: {
              categories: this.rateData.categories
            },
            tooltip: {
              custom: function (data) {
                return (
                  '<div class="px-1 py-50">' +
                  '<span>' +
                  data.series[data.seriesIndex][data.dataPointIndex] +
                  '%</span>' +
                  '</div>'
                );
              }
            }
          };
        },
        error: (e) => console.error(e)
      });
  }

  ngOnChanges(): void {
    this.DateRangeOptions.defaultDate = this.period
    if (this.disable_period == true) {
      this.DateRangeOptions.enable = this.period
    }

    this.retrieveData();
  }

}
