import { Component, OnInit, ViewEncapsulation, ViewChild, Input, OnChanges } from '@angular/core';
import {
  ApexChart,
  ApexStroke,
  ApexDataLabels,
  ApexXAxis,
  ApexTooltip,
  ApexPlotOptions,
  ApexYAxis,
  ApexFill,
  ApexMarkers,
  ApexNonAxisChartSeries,
  ApexLegend,
  ApexResponsive,
  ApexStates
} from 'ng-apexcharts';

import { StatisticService } from 'app/service/statistic/statistic.service';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { DatePipe } from '@angular/common';
import { colors } from 'app/colors.const';

export interface ChartOptions2 {
  // Apex-non-axis-chart-series
  series?: ApexNonAxisChartSeries;
  chart?: ApexChart;
  stroke?: ApexStroke;
  tooltip?: ApexTooltip;
  dataLabels?: ApexDataLabels;
  fill?: ApexFill;
  colors?: string[];
  legend?: ApexLegend;
  labels?: any;
  plotOptions?: ApexPlotOptions;
  responsive?: ApexResponsive[];
  markers?: ApexMarkers[];
  xaxis?: ApexXAxis;
  yaxis?: ApexYAxis;
  states?: ApexStates;
}

@Component({
  selector: 'app-by-gender',
  templateUrl: './by-gender.component.html',
  styleUrls: ['./by-gender.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ByGenderComponent implements OnChanges {

  @Input() period;
  @Input() disable_period;
  @Input() campaign;
  @Input() seller;

  @ViewChild('byGenderRef') byGenderRef: any;
  public byGender: Partial<ChartOptions2>;

  public byGenderData

  constructor(private statisticService: StatisticService, private datepipe: DatePipe) { }

  public DateRangeOptions: FlatpickrOptions = {
    altInput: true,
    mode: 'range',
    altFormat: 'Y-n-j',
  };

  retrieveByGender() {
    var startDate = this.datepipe.transform(this.period[0], 'yyyy-MM-dd')
    var endDate = startDate
    if (this.period[1] != null) {
      endDate = this.datepipe.transform(this.period[1], 'yyyy-MM-dd')
    }
    this.statisticService.byGender(startDate, endDate, this.campaign, this.seller)
      .subscribe({
        next: (data) => {
          this.byGenderData = data
          var total = this.byGenderData.total
          this.byGender =
          {
            series: this.byGenderData.series,
            chart: {
              height: 450,
              type: 'donut'
            },
            colors: [colors.solid.info, '#FFA1A1', '#ffe700'],
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    name: {
                      fontSize: '2rem',
                      fontFamily: 'Montserrat'
                    },
                    value: {
                      fontSize: '2rem',
                      fontFamily: 'Montserrat',
                      formatter: function (val) {
                        return val + ' Client(s)';
                      }
                    },
                    total: {
                      show: true,
                      fontSize: '1.5rem',
                      label: 'Total',
                      formatter: function (w) {
                        return total + ' Client(s)'
                      }
                    }
                  }
                }
              }
            },
            legend: {
              show: true,
              position: 'bottom'
            },
            labels: this.byGenderData.labels,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    height: 300
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              }
            ]
          }
        },
        error: (e) => console.error(e)
      });
  }

  ngOnChanges(): void {
    this.DateRangeOptions.defaultDate = this.period
    if (this.disable_period == true) {
      this.DateRangeOptions.enable = this.period
    }

    this.retrieveByGender();
  }

}