import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Swal from 'sweetalert2';

import { HeardAboutUsService } from 'app/service/heard-about-us/heard-about-us.service';
import { ToastService } from 'app/service/toast/toast.service';

@Component({
  selector: 'app-heard-about-us',
  templateUrl: './heard-about-us.component.html',
  styleUrls: ['./heard-about-us.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeardAboutUsComponent implements OnInit {

  public loadedData = false

  public heard_about_uss;
  public currentHeardAboutUs
  private tempHeardAboutUsData = [];
  public heard_about_usRows: any;
  public exportCSVData

  public ColumnMode = ColumnMode;
  public basicSelectedOption: number = 25;
  public SelectionType = SelectionType;
  public selected = [];
  public chkBoxSelected = [];

  public heard_about_usForm: FormGroup;
  public heard_about_usSubmitted = false
  public loadingSubmitHeardAboutUs = false

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    private heard_about_usService: HeardAboutUsService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
  ) { }

  // convenience getter for easy access to heard_about_us form fields
  get p() {
    return this.heard_about_usForm.controls;
  }

  deleteHeardAboutUs(res, id) {
    if (res.value == true) {
      this.heard_about_usService.delete(id)
        .subscribe({
          next: (response) => {
            this.toastService.error('Niveau supprimé', 'Niveau supprimé avec succès')
          },
          error: (e) => console.error(e)
        });
    }
  }
  updateStatut(res, heard_about_us) {
    if (res.value == true) {
      this.heard_about_usService.updateStatut(heard_about_us.id, !heard_about_us.statut)
        .subscribe({
          next: (response) => {
          },
          error: (e) => console.error(e)
        });
    }
  }
  updateHeardAboutUs() {
    this.heard_about_usService.update(this.currentHeardAboutUs.id, this.p.title.value)
      .subscribe({
        next: (response) => {
          this.heard_about_usSubmitted = false
          this.loadingSubmitHeardAboutUs = false
          this.modalService.dismissAll()
          this.toastService.info('Niveau mis à jour', 'Niveau mis à jour avec succès')
          this.heard_about_usForm.reset()
        },
        error: (e) => {
          this.loadingSubmitHeardAboutUs = false
          this.heard_about_usSubmitted = false
          console.error(e)
        }
      });
  }
  createHeardAboutUs() {
    this.heard_about_usSubmitted = true
    if (this.heard_about_usForm.invalid) {
      return
    } else {
      this.loadingSubmitHeardAboutUs = true
      this.heard_about_usService.create(this.p.title.value)
        .subscribe({
          next: (response) => {
            this.heard_about_usSubmitted = false
            this.loadingSubmitHeardAboutUs = false
            this.modalService.dismissAll()
            this.toastService.success('Niveau créé', 'Niveau créé avec succès')
            this.heard_about_usForm.reset()
          },
          error: (e) => {
            this.loadingSubmitHeardAboutUs = false
            this.heard_about_usSubmitted = false
            console.error(e)
          }
        });
    }
  }
  submitHeardAboutUs() {
    if (this.currentHeardAboutUs) {
      this.updateHeardAboutUs()
    } else {
      this.createHeardAboutUs()
    }
  }

  retrieveHeardAboutUss(): void {
    this.heard_about_usService.getAll()
      .subscribe({
        next: (data) => {
          this.heard_about_uss = data;
          this.loadedData = true
          this.tempHeardAboutUsData = this.heard_about_uss;
          this.heard_about_usRows = this.heard_about_uss;
          this.exportCSVData = this.heard_about_uss;
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.heard_about_usService.refreshHeardAboutUs.subscribe(() => {
      this.retrieveHeardAboutUss()
    })
    this.retrieveHeardAboutUss();

    //heard_about_us
    this.heard_about_usForm = this._formBuilder.group({
      title: ['', [Validators.required]],
    });
  }

  modalOpen(modalForm, heard_about_us) {
    this.currentHeardAboutUs = null
    this.heard_about_usForm.reset()
    this.modalService.open(modalForm);
    if (heard_about_us) {
      this.currentHeardAboutUs = heard_about_us
      this.heard_about_usForm.patchValue({
        title: heard_about_us.title,
        price: heard_about_us.price,
      });
    }
  }

  filterHeardAboutUss(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.tempHeardAboutUsData.filter(function (d) {
      return d.title.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.heard_about_usRows = temp;
  }

  confirmUpdateStatus(heard_about_us) {
    Swal.fire({
      title: `${heard_about_us.statut == true ? "Désactiver" : "Activer"} ${heard_about_us.title}`,
      text: `Vous êtes sûr de vouloir ${heard_about_us.statut == true ? "Désactiver" : "Activer"} ${heard_about_us.title}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Oui',
      cancelButtonText: 'Annuler',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(res => this.updateStatut(res, heard_about_us));
  }
  confirmDeleteHeardAboutUs(heard_about_us) {
    Swal.fire({
      title: `Vous êtes sûr de vouloir supprimer ${heard_about_us.title}?`,
      text: 'Vous ne serez pas en mesure de la récupérer!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Oui',
      cancelButtonText: 'Annuler',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(res => this.deleteHeardAboutUs(res, heard_about_us.id));
  }
}
