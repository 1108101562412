<div class="text-center" *ngIf="!loadedData">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-header">
                <h3 class="text-primary badge badge-light-primary" style="padding: 1rem 1rem;font-size: 120%;">
                    <i data-feather="send" class="font-medium-3"></i> Liste des campagnes
                </h3>
                <div class="card-actions" *ngIf="checkAccess('add')">
                    <button class="btn btn-primary" (click)="modalOpen(modalCampaignForm)" rippleEffect>
                        <span data-feather="plus"></span> Ajouter une campagne
                    </button>
                </div>
            </div>
            <div class="card-body">
                <div class="users-list-filter">
                    <form [formGroup]="filterForm" (ngSubmit)="submitFilter()" class="row justify-content-center">
                        <div class="col-md-2 col-12">
                            <ng-select formControlName="statut" placeholder="Statut">
                                <ng-option value="1">Active</ng-option>
                                <ng-option value="0">Inactive</ng-option>
                            </ng-select>
                        </div>
                        <div class="col-md-3 col-12">
                            <ng2-flatpickr [config]="filterRange" name="filterRange" placeholder="Date de la campagne"
                                formControlName="date"></ng2-flatpickr>
                        </div>
                        <div class="col-md-2 col-12">
                            <button type="submit" [disabled]="loadingSubmitFilter" class="btn btn-outline-primary"
                                rippleEffect>
                                <span *ngIf="loadingSubmitFilter" class="spinner-border spinner-border-sm"></span>
                                <span data-feather="filter"></span> Rechercher
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="card" *ngIf="loadedData">
            <div class="row">
                <div class="col-md-6 col-12">
                    <div class="d-flex justify-content-between align-items-center m-1">
                        <label class="d-flex align-items-center">Afficher
                            <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                                <option value="5">5</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            entrées</label>
                    </div>
                </div>
                <div class="col-md-6 col-12 d-flex justify-content-md-end">
                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                        <label class="d-flex align-items-center">Recherche<input type="search" placeholder="recherche"
                                class="form-control ml-25" (keyup)="filterCampaigns($event)"
                                (search)="filterCampaigns($event)" /></label>
                    </div>
                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                        <a csvLink [data]="exportCSVData" class="btn btn-outline-secondary mr-1" rippleEffect>
                            Exporter CSV</a>
                    </div>
                </div>
            </div>

            <!-- Campaigns Datatable -->
            <ngx-datatable [rows]="campaignRows" [rowHeight]="58" class="bootstrap core-bootstrap" [limit]="5"
                [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
                [selectionType]="SelectionType.checkbox" [limit]="basicSelectedOption"
                (select)="onSelectCampaign($event)">
                <ngx-datatable-column name="Référence" prop="reference" [width]="90"></ngx-datatable-column>
                <ngx-datatable-column name="Budget" [width]="90">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        {{row.budget}} Dt
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Date" [width]="100">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        <b>De </b><span>{{row.start_date}}</span><b><br> à </b><span>{{row.end_date}}</span>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Packs" [width]="100">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        <div *ngFor="let pack of row.packs">{{pack.title}}</div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="N° prospects" prop="customers_number" [width]="90"></ngx-datatable-column>
                <ngx-datatable-column name="Statut" [width]="80">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        <div class="custom-control custom-control-success custom-switch">
                            <input type="checkbox" class="custom-control-input switch" id="{{ row.id }}"
                                [checked]="row.statut" (click)="confirmUpdateStatus(row); false">
                            <label class="custom-control-label" for="{{ row.id }}"></label>
                        </div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Durée" [width]="100">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        <div class="progress-wrapper">
                            <div class="mb-25">Progrés {{row.duration_percentage}} %</div>
                            <ngb-progressbar [type]="row.duration_percentage == 100 ? 'primary' : 'warning'"
                                [value]="row.duration_percentage"></ngb-progressbar>
                        </div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Actions" [width]="120" [sortable]="false">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        <div class="d-flex align-items-center">
                            <button type="button" *ngIf="checkAccess('read')" routerLink="/campaign/{{row.id}}/detail"
                                class="btn btn-raised btn-outline-secondary btn-sm mr-1" rippleEffect>
                                <i data-feather="file-text"></i>
                            </button>
                            <button type="button" *ngIf="checkAccess('edit')"
                                (click)="modalOpen(modalCampaignForm, row)"
                                class="btn btn-raised btn-outline-primary btn-sm mr-1" rippleEffect>
                                <i data-feather="edit"></i>
                            </button>
                            <button type="button" *ngIf="checkAccess('delete')" (click)="confirmDeleteCampaign(row)"
                                class="btn btn-raised btn-outline-danger btn-sm" rippleEffect>
                                <i data-feather="trash"></i>
                            </button>
                        </div>
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
            <!--/ Campaigns Datatable -->
        </div>

        <app-campaign-cost-histogram [statut]="filterForm.get('statut').value"
            [period]="period"></app-campaign-cost-histogram>
    </div>
</div>

<!-- Campaign Modal -->
<ng-template #modalCampaignForm let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">
            {{ currentCampaign ? ('Mettre à jour #' + currentCampaign.reference) : 'Nouvelle campagne'}}
        </h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="campaignForm" (ngSubmit)="submitCampaign()">
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <div class="row">
                <div class="col-md-6 col-12">
                    <label>Référence: </label>
                    <div class="form-group">
                        <input type="text" class="form-control" formControlName="reference" placeholder="Référence"
                            [ngClass]="{ 'is-invalid': campaignSubmitted && c.reference.errors }" />
                        <div *ngIf="campaignSubmitted && c.reference.errors" class="invalid-feedback">
                            <div *ngIf="c.reference.errors.required">Référence est requise</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-12">
                    <label>Budget: </label>
                    <div class="form-group">
                        <input type="number" class="form-control" formControlName="budget" placeholder="Budget"
                            [ngClass]="{ 'is-invalid': campaignSubmitted && c.budget.errors }" />
                        <div *ngIf="campaignSubmitted && c.budget.errors" class="invalid-feedback">
                            <div *ngIf="c.budget.errors.required">Budget est requis</div>
                        </div>
                    </div>
                </div>
            </div>
            <label>De.. à..: </label>
            <div class="form-group">
                <ng2-flatpickr [config]="DateRangeOptions" name="DateRange" formControlName="date"
                    placeholder="{{currentCampaign ? currentCampaign.start_date + ' to ' + currentCampaign.end_date :'De.. à..'}}"
                    [ngClass]="{ 'is-invalid': campaignSubmitted && c.date.errors }"></ng2-flatpickr>
                <div *ngIf="campaignSubmitted && c.date.errors" class="invalid-feedback">
                    <div *ngIf="c.date.errors.required">Date est requise</div>
                </div>
            </div>
            <label>Packs: </label>
            <div class="form-group">
                <ng-select formControlName="pack" placeholder="Pack" multiple="true"
                    [ngClass]="{ 'is-invalid': campaignSubmitted && c.pack.errors }">
                    <ng-option *ngFor="let pack of packs" [value]="pack.id">{{pack.title}}</ng-option>
                </ng-select>
                <div *ngIf="campaignSubmitted && c.pack.errors" class="invalid-feedback">
                    <div *ngIf="c.pack.errors.required">Packs est requises</div>
                </div>
            </div>
            <label>Canaux: </label>
            <div class="form-group">
                <ng-select formControlName="channels" placeholder="Canaux" multiple="true"
                    [ngClass]="{ 'is-invalid': campaignSubmitted && c.pack.errors }">
                    <ng-option *ngFor="let channel of contactChannels"
                        [value]="channel.id">{{channel.title}}</ng-option>
                </ng-select>
                <div *ngIf="campaignSubmitted && c.pack.errors" class="invalid-feedback">
                    <div *ngIf="c.pack.errors.required">Canaux est requises</div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" [disabled]="loadingSubmitCampaign" class="btn btn-primary">
                <span *ngIf="loadingSubmitCampaign" class="spinner-border spinner-border-sm mr-1"></span>
                Enregistrer
            </button>
        </div>
    </form>
</ng-template>
<!-- / Campaign Modal -->