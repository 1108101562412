import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';

import { FreeSessionService } from 'app/service/free-session/free-session.service';
import { SubjectService } from 'app/service/subject/subject.service';

@Component({
  selector: 'app-free-session',
  templateUrl: './free-session.component.html',
  styleUrls: ['./free-session.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FreeSessionComponent implements OnInit {

  public loadedData = false
  public loadedDatatable = false

  public freeSessions;
  private tempFreeSessionData = [];
  public freeSessionRows: any;
  public exportCSVData

  public ColumnMode = ColumnMode;
  public basicSelectedOption: number = 25;
  public SelectionType = SelectionType;
  public selected = [];
  public chkBoxSelected = [];

  public subjects

  public currentPeriod = 'future'
  public selectedSubjects = []

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(private freeSessionService: FreeSessionService, private subjectService: SubjectService) { }

  retrieveSubjects(){
    this.subjectService.getAll()
    .subscribe({
      next: (data) => {
        this.subjects = data;
      },
      error: (e) => console.error(e)
    });
  }

  retrieveFreeSessions(): void {
    this.loadedDatatable = false
    this.freeSessionService.getByPeriodAndSubjects(this.currentPeriod, this.selectedSubjects)
      .subscribe({
        next: (data) => {
          this.freeSessions = data;
          this.loadedData = true
          this.loadedDatatable = true
          this.tempFreeSessionData = this.freeSessions;
          this.freeSessionRows = this.freeSessions;
          this.exportCSVData = this.freeSessions;
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.freeSessionService.refreshSession.subscribe(() => {
      this.retrieveFreeSessions()
    })
    this.retrieveFreeSessions();
    this.retrieveSubjects()
  }

  changePeriod(period) {
    this.currentPeriod = period
    this.retrieveFreeSessions()
  }

  filterFreeSessions(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.tempFreeSessionData.filter(function (d) {
      if (d.date.toLowerCase().includes(val) || d.time == val || d.subject.title.toLowerCase().includes(val) || (d.customer.first_name != null && d.customer.first_name.toLowerCase().includes(val)) || (d.customer.last_name != null && d.customer.last_name.toLowerCase().includes(val))) {
        return d
      }
    });
    this.freeSessionRows = temp;
  }
}
