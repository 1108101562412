import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';

import { ContactService } from 'app/service/contact/contact.service';

@Component({
  selector: 'app-seller-contact',
  templateUrl: './seller-contact.component.html',
  styleUrls: ['./seller-contact.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SellerContactComponent implements OnInit {

  public loadedData = false

  public seller_id
  public global_period
  public period

  public data

  public contacts;
  public currentContact
  private tempContactData = [];
  public contactRows: any;
  public exportCSVData

  public ColumnMode = ColumnMode;
  public basicSelectedOption: number = 25;
  public SelectionType = SelectionType;
  public selected = [];
  public chkBoxSelected = [];

  public searchForm: FormGroup;

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(private contactService: ContactService,
    private route: ActivatedRoute,
    private datepipe: DatePipe,
    private _formBuilder: FormBuilder
  ) { 
  }
  public globalDateOptions = {
    altInput: true,
    mode: 'range',
    defaultDate: [],
    maxDate: null,
    altFormat: 'Y-n-j',
  };

  retrieveContacts(): void {
    var startDate = this.datepipe.transform(this.period[0], 'yyyy-MM-dd')
    var endDate = startDate
    if (this.period[1] != null) {
      endDate = this.datepipe.transform(this.period[1], 'yyyy-MM-dd')
    }
    this.contactService.getByUser(this.seller_id, startDate, endDate, this.searchForm.get('contact_statut').value, this.searchForm.get('customer_statut').value)
      .subscribe({
        next: (data) => {
          this.data = data;
          this.contacts = this.data.data
          this.loadedData = true
          this.tempContactData = this.contacts;
          this.contactRows = this.contacts;
          this.exportCSVData = this.contactRows.map((item) => {
            return ({ Date: item.date, lHeure: 'De ' + item.start_time + ' à ' + item.end_time, Durée: item.duration + 'min', Joignabilité: item.reachability ? 'joignable' : 'injoignable' });
          });        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.seller_id = this.route.snapshot.paramMap.get('id');

    var start = new Date().setDate((new Date()).getDate() - 7);
    var end = new Date();

    this.globalDateOptions.maxDate = new Date()
    this.period = [start, end]

    //search
    this.searchForm = this._formBuilder.group({
      contact_statut: [null],
      customer_statut: [null],
    });

    this.changePeriod();
  }

  changePeriod() {
    if (this.period[1]) {
      this.retrieveContacts();

      this.global_period = [this.datepipe.transform(this.period[0], 'yyyy-MM-dd'), this.datepipe.transform(this.period[1], 'yyyy-MM-dd')]
      this.globalDateOptions.defaultDate = this.global_period
    }
  }

  filterContacts(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.tempContactData.filter(function (d) {
      if ((d.phone != null && d.phone.toLowerCase().includes(val)) || (d.first_name != null && d.first_name.toLowerCase().includes(val)) || (d.last_name != null && d.last_name.includes(val))) {
        return d
      }
    });
    this.contactRows = temp;
    this.exportCSVData = this.contactRows
  }
}
