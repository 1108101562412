import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Swal from 'sweetalert2';

import { LevelService } from 'app/service/level/level.service';
import { ToastService } from 'app/service/toast/toast.service';

@Component({
  selector: 'app-level',
  templateUrl: './level.component.html',
  styleUrls: ['./level.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LevelComponent implements OnInit {

  public loadedData = false

  public levels;
  public currentLevel
  private tempLevelData = [];
  public levelRows: any;
  public exportCSVData

  public ColumnMode = ColumnMode;
  public basicSelectedOption: number = 25;
  public SelectionType = SelectionType;
  public selected = [];
  public chkBoxSelected = [];

  public levelForm: FormGroup;
  public levelSubmitted = false
  public loadingSubmitLevel = false

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    private levelService: LevelService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
  ) { }

  // convenience getter for easy access to level form fields
  get p() {
    return this.levelForm.controls;
  }

  deleteLevel(res, id) {
    if (res.value == true) {
      this.levelService.delete(id)
        .subscribe({
          next: (response) => {
            this.toastService.error('Niveau supprimé', 'Niveau supprimé avec succès')
          },
          error: (e) => console.error(e)
        });
    }
  }
  updateLevel() {
    this.levelService.update(this.currentLevel.id, this.p.title.value)
      .subscribe({
        next: (response) => {
          this.levelSubmitted = false
          this.loadingSubmitLevel = false
          this.modalService.dismissAll()
          this.toastService.info('Niveau mis à jour', 'Niveau mis à jour avec succès')
          this.levelForm.reset()
        },
        error: (e) => {
          this.loadingSubmitLevel = false
          this.levelSubmitted = false
          console.error(e)
        }
      });
  }
  createLevel() {
    this.levelSubmitted = true
    if (this.levelForm.invalid) {
      return
    } else {
      this.loadingSubmitLevel = true
      this.levelService.create(this.p.title.value)
        .subscribe({
          next: (response) => {
            this.levelSubmitted = false
            this.loadingSubmitLevel = false
            this.modalService.dismissAll()
            this.toastService.success('Niveau créé', 'Niveau créé avec succès')
            this.levelForm.reset()
          },
          error: (e) => {
            this.loadingSubmitLevel = false
            this.levelSubmitted = false
            console.error(e)
          }
        });
    }
  }
  submitLevel() {
    if (this.currentLevel) {
      this.updateLevel()
    } else {
      this.createLevel()
    }
  }

  retrieveLevels(): void {
    this.levelService.getAll()
      .subscribe({
        next: (data) => {
          this.levels = data;
          this.loadedData = true
          this.tempLevelData = this.levels;
          this.levelRows = this.levels;
          this.exportCSVData = this.levels;
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.levelService.refreshLevel.subscribe(() => {
      this.retrieveLevels()
    })
    this.retrieveLevels();

    //level
    this.levelForm = this._formBuilder.group({
      title: ['', [Validators.required]],
    });
  }

  modalOpen(modalForm, level) {
    this.currentLevel = null
    this.levelForm.reset()
    this.modalService.open(modalForm);
    if (level) {
      this.currentLevel = level
      this.levelForm.patchValue({
        title: level.title,
      });
    }
  }

  filterLevels(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.tempLevelData.filter(function (d) {
      return d.title.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.levelRows = temp;
  }

  confirmDeleteLevel(level) {
    Swal.fire({
      title: `Vous êtes sûr de vouloir supprimer ${level.title}?`,
      text: 'Vous ne serez pas en mesure de la récupérer!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Oui',
      cancelButtonText: 'Annuler',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(res => this.deleteLevel(res, level.id));
  }
}
