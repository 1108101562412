<div class="text-center" *ngIf="!loadedData">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<div class="content-wrapper container-xxl p-0" *ngIf="loadedData">
    <div class="content-body">
        <div class="card">
            <div class="card-header">
                <h3 class="text-primary badge badge-light-primary" style="padding: 1rem 1rem;font-size: 120%;">
                    <i data-feather="message-circle" class="font-medium-3"></i> Liste des sms
                </h3>
            </div>
            <div class="row">
                <div class="col-md-6 col-12">
                    <div class="d-flex justify-content-between align-items-center m-1">
                        <label class="d-flex align-items-center">Afficher
                            <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                                <option value="5">5</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            entrées</label>
                    </div>
                </div>
                <div class="col-md-6 col-12 d-flex justify-content-md-end">
                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                        <label class="d-flex align-items-center">Recherche<input type="search" placeholder="recherche"
                                class="form-control ml-25" (keyup)="filterSmsHistorys($event)"
                                (search)="filterSmsHistorys($event)" /></label>
                    </div>
                </div>
            </div>

            <!-- SmsHistorys Datatable -->
            <ngx-datatable [rows]="sms_historyRows" [rowHeight]="200" class="bootstrap core-bootstrap" [limit]="5"
                [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
                [selectionType]="SelectionType.checkbox" [limit]="basicSelectedOption" (select)="onSelectSmsHistory($event)">
                <ngx-datatable-column name="Contenu" prop="content" [width]="100"></ngx-datatable-column>
                <ngx-datatable-column name="Campagne" prop="campaign" [width]="80"></ngx-datatable-column>
                <ngx-datatable-column name="Envoyé à" [width]="150">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        <span *ngFor="let phone of row.customers; let i = index">
                            <span class="badge badge-light-primary mr-1">{{phone}}</span>
                            <span *ngIf="i % 2 ==  0"><br></span>
                        </span>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Date" prop="created_at" [width]="80"></ngx-datatable-column>
            </ngx-datatable>
            <!--/ SmsHistorys Datatable -->
        </div>
    </div>
</div>