import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';

import { CoreCommonModule } from '@core/common.module';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';

import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { CustomerConversionComponent } from './customer-conversion/customer-conversion.component';
import { CustomerReachabilityComponent } from './customer-reachability/customer-reachability.component';
import { ByGovernorateComponent } from './by-governorate/by-governorate.component';
import { ByContactChannelComponent } from './by-contact-channel/by-contact-channel.component';
import { ByLevelComponent } from './by-level/by-level.component';
import { ByReachabilityComponent } from './by-reachability/by-reachability.component';
import { HistogramComponent } from './histogram/histogram.component';
import { NumberComponent } from './number/number.component';
import { ByGenderComponent } from './by-gender/by-gender.component';
import { ContactTypeComponent } from './contact-type/contact-type.component';
import { CallDurationHistogramComponent } from './call-duration-histogram/call-duration-histogram.component';
import { CustomerContactedAndConvertedComponent } from './customer-contacted-and-converted/customer-contacted-and-converted.component';
import { CampaignCostHistogramComponent } from './campaign-cost-histogram/campaign-cost-histogram.component';

// routing
const routes: Routes = [];

@NgModule({
  declarations: [
    CustomerConversionComponent,
    CustomerReachabilityComponent,
    ByGovernorateComponent,
    ByContactChannelComponent,
    ByLevelComponent,
    ByReachabilityComponent,
    HistogramComponent,
    NumberComponent,
    ByGenderComponent,
    ContactTypeComponent,
    CallDurationHistogramComponent,
    CustomerContactedAndConvertedComponent,
    CampaignCostHistogramComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ContentHeaderModule,
    NgApexchartsModule,
    CardSnippetModule,
    Ng2FlatpickrModule,
    CoreCommonModule,
    NgbModule
  ],
  exports: [
    NumberComponent,
    HistogramComponent,
    CustomerConversionComponent,
    CustomerContactedAndConvertedComponent,
    CustomerReachabilityComponent,
    ByGenderComponent,
    ByGovernorateComponent,
    ByContactChannelComponent,
    ByLevelComponent,
    ByReachabilityComponent,
    ContactTypeComponent,
    CallDurationHistogramComponent,
    CampaignCostHistogramComponent
  ]
})
export class StatisticsModule { }
