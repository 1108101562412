import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Swal from 'sweetalert2';

import { AuthenticationService } from 'app/auth/service';
import { UserService } from 'app/service/user/user.service';
import { ToastService } from 'app/service/toast/toast.service';
import { RoleService } from 'app/service/role/role.service';
import { CenterService } from 'app/service/center/center.service';

@Component({
  selector: 'app-seller',
  templateUrl: './seller.component.html',
  styleUrls: ['./seller.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SellerComponent implements OnInit {

  public loadedData = false

  public sellers;
  public currentSeller
  private tempSellerData = [];
  public sellerRows: any;
  public exportCSVData

  public ColumnMode = ColumnMode;
  public basicSelectedOption: number = 25;
  public SelectionType = SelectionType;
  public selected = [];
  public chkBoxSelected = [];

  public roles
  public seller_role
  public centers

  public sellerForm: FormGroup;
  public sellerSubmitted = false
  public loadingSubmitSeller = false

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    private _authenticationService: AuthenticationService,
    private sellerService: UserService,
    private roleService: RoleService,
    private centerService: CenterService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
  ) { }

  // convenience getter for easy access to seller form fields
  get u() {
    return this.sellerForm.controls;
  }

  deleteSeller(res, id) {
    if (res.value == true) {
      this.sellerService.delete(id)
        .subscribe({
          next: (response) => {
            this.toastService.error('Utilisateur supprimé', 'Utilisateur supprimé avec succès')
          },
          error: (e) => console.error(e)
        });
    }
  }
  updateSeller() {
    this.sellerService.update(this.currentSeller.id, this.u.username.value, this.seller_role.id, this.u.center.value)
      .subscribe({
        next: (response) => {
          this.sellerSubmitted = false
          this.loadingSubmitSeller = false
          this.modalService.dismissAll()
          this.toastService.info('Utilisateur mis à jour', 'Utilisateur mis à jour avec succès')
          this.sellerForm.reset()
        },
        error: (e) => {
          this.loadingSubmitSeller = false
          this.sellerSubmitted = false
          console.error(e)
        }
      });
  }
  createSeller() {
    this.sellerSubmitted = true
    if (this.sellerForm.invalid) {
      return
    } else {
      this.loadingSubmitSeller = true
      this.sellerService.create(this.u.username.value, this.seller_role.id, this.u.center.value, this.u.password.value)
        .subscribe({
          next: (response) => {
            this.sellerSubmitted = false
            this.loadingSubmitSeller = false
            this.modalService.dismissAll()
            this.toastService.success('Utilisateur créé', 'Utilisateur créé avec succès')
            this.sellerForm.reset()
          },
          error: (e) => {
            this.loadingSubmitSeller = false
            this.sellerSubmitted = false
            console.error(e)
          }
        });
    }
  }
  submitSeller() {
    if (this.currentSeller) {
      this.updateSeller()
    } else {
      this.createSeller()
    }
  }

  retrieveCenters(): void {
    this.centerService.getAll()
      .subscribe({
        next: (data) => {
          this.centers = data;
        },
        error: (e) => console.error(e)
      });
  }

  retrieveRoles(): void {
    this.roleService.getAll()
      .subscribe({
        next: (data) => {
          this.roles = data;
          this.seller_role = this.roles.find(item => item.value == 'ROLE_SELLER')
        },
        error: (e) => console.error(e)
      });
  }

  retrieveSellers(): void {
    this.sellerService.getSeller()
      .subscribe({
        next: (data) => {
          this.sellers = data;
          this.loadedData = true
          this.tempSellerData = this.sellers;
          this.sellerRows = this.sellers;
          this.exportCSVData = this.sellers;
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.sellerService.refreshUser.subscribe(() => {
      this.retrieveSellers()
    })
    this.retrieveSellers();
    this.retrieveRoles();
    this.retrieveCenters();

    //seller
    this.sellerForm = this._formBuilder.group({
      username: ['', [Validators.required]],
      center: ['', [Validators.required]],
      password: ['']
    });
  }

  checkAccess(action) {
    var seller = this._authenticationService.currentUserValue
    var page = seller.access.find(item => item.pageTitle == 'Vendeur')
    if (page != null && (page.pageActions.includes('all') || page.pageActions.includes(action))) {
      return true
    } else {
      return false
    }
  }

  modalOpen(modalForm, seller) {
    this.currentSeller = null
    this.sellerForm.reset()
    this.modalService.open(modalForm);
    if (seller) {
      this.currentSeller = seller
      this.sellerForm.patchValue({
        username: seller.username,
        role: seller.role.id,
        center: seller.center != null ? seller.center.id : null
      });
    }
  }

  filterSellers(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.tempSellerData.filter(function (d) {
      return d.username.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.sellerRows = temp;
  }

  confirmDeleteSeller(seller) {
    Swal.fire({
      title: `Vous êtes sûr de vouloir supprimer ${seller.username}?`,
      text: 'Vous ne serez pas en mesure de la récupérer!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Oui',
      cancelButtonText: 'Annuler',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(res => this.deleteSeller(res, seller.id));
  }
}
