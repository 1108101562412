<div class="card">
  <div
    class="card-header d-flex flex-sm-row flex-column justify-content-md-between align-items-start justify-content-start">
    <div>
      <h4 class="card-title mb-25">Taux de conversion</h4>
      <span class="card-subtitle text-muted">Clients convertis</span>
    </div>
    <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
      De {{period[0]}} à {{period[1]}}
      <!-- <ng2-flatpickr [config]="DateRangeOptions" name="DateRange" [(ngModel)]="period" (ngModelChange)="retrieveData()"
        class="mr-1"></ng2-flatpickr> -->
    </div>
  </div>
  <div class="card-body">
    <div id="line-chart" #convertedCustomersRateRef *ngIf="rateData">
      <apx-chart [series]="convertedCustomersRate.series" [chart]="convertedCustomersRate.chart"
        [xaxis]="convertedCustomersRate.xaxis" [stroke]="convertedCustomersRate.stroke"
        [tooltip]="convertedCustomersRate.tooltip" [colors]="convertedCustomersRate.colors"
        [dataLabels]="convertedCustomersRate.dataLabels" [grid]="convertedCustomersRate.grid"></apx-chart>
    </div>
  </div>
</div>