import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SmsHistoryService {

  private _refreshSmsHistory = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshSmsHistory() {
    return this._refreshSmsHistory;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/api/sms_history/list`);
  }

}
