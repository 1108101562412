<li class="nav-item nav-search">
  <!-- Search icon -->
  <a class="nav-link nav-link-search" (click)="toggleSearch()"><span [data-feather]="'search'"
      [class]="'ficon'"></span></a>

  <!-- Search -->
  <div class="search-input" [class.open]="openSearchRef === true">
    <!-- Search Input -->
    <div class="search-input-icon"><span [data-feather]="'search'"></span></div>
    <input class="form-control input" type="text" placeholder="Saisir un numéro de téléphone" tabindex="-1"
      data-search="search" (keyup)="searchUpdate($event)" [(ngModel)]="searchText" #openSearch /><button
      class="btn search-input-close p-0" (click)="toggleSearch()"><span [data-feather]="'x'"></span></button>

    <!-- Search List -->
    <ul class="search-list search-list-main" [perfectScrollbar]  [class.show]="searchText !== ''" #pageList>
      <!-- Pages -->
      <!-- <li class="d-flex align-items-center">  
        <a href="javascript:void(0)"><h6 class="section-label mt-75 mb-0">Pages</h6></a>
      </li> -->
      <ng-container *ngIf="customer; else noResult">
        <li>{{customer.firstName}}</li>
      </ng-container>

      <!-- No results found -->
      <ng-template #noResult>
        <li class="auto-suggestion justify-content-between">
          <a class="d-flex align-items-center justify-content-between w-100 py-50">
            <div class="d-flex justify-content-start">
              <i data-feather="alert-circle" class="mr-25"></i>
              <span *ngIf="phone.length == 8; else phoneLength">Aucun résultat trouvé.</span>
              <span #phoneLength>Le numéro de téléphone doit comporter 8 caractères.</span>
            </div></a
          >
        </li>
      </ng-template>
      <!--/ Pages -->
    </ul>
    <!--/ Search List -->
  </div>
</li>