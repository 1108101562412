<div class="card">
    <div
        class="card-header d-flex flex-sm-row flex-column justify-content-md-between align-items-start justify-content-start">
        <div>
            <h4 class="card-title mb-25">Distribution des clients par genre</h4>
        </div>
        <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
            <span class="badge badge-light-primary" *ngIf="byGenderData && byGenderData.total">{{byGenderData.total}} clients</span>
            <!-- <ng2-flatpickr [config]="DateRangeOptions" name="DateRange" [(ngModel)]="period"
                (ngModelChange)="retrieveByGender()" class="mr-1"></ng2-flatpickr> -->
        </div>
    </div>
    <div class="card-body" *ngIf="byGenderData">
        <div id="donut-chart" #byGenderRef>
            <apx-chart [series]="byGender.series" [chart]="byGender.chart" [labels]="byGender.labels"
                [plotOptions]="byGender.plotOptions" [responsive]="byGender.responsive" [colors]="byGender.colors"
                [legend]="byGender.legend"></apx-chart>
        </div>
    </div>
</div>