import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Swal from 'sweetalert2';

import { ContactChannelService } from 'app/service/contact-channel/contact-channel.service';
import { ToastService } from 'app/service/toast/toast.service';
import { AuthenticationService } from 'app/auth/service';

@Component({
  selector: 'app-contact-channel',
  templateUrl: './contact-channel.component.html',
  styleUrls: ['./contact-channel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactChannelComponent implements OnInit {

  public loadedData = false

  public contact_channels;
  public currentContactChannel
  private tempContactChannelData = [];
  public contact_channelRows: any;
  public exportCSVData

  public ColumnMode = ColumnMode;
  public basicSelectedOption: number = 25;
  public SelectionType = SelectionType;
  public selected = [];
  public chkBoxSelected = [];

  public contact_channelForm: FormGroup;
  public contact_channelSubmitted = false
  public loadingSubmitContactChannel = false

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    private _authenticationService: AuthenticationService,
    private contact_channelService: ContactChannelService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
  ) { }

  // convenience getter for easy access to contact_channel form fields
  get p() {
    return this.contact_channelForm.controls;
  }

  deleteContactChannel(res, id) {
    if (res.value == true) {
      this.contact_channelService.delete(id)
        .subscribe({
          next: (response) => {
            this.toastService.error('Niveau supprimé', 'Niveau supprimé avec succès')
          },
          error: (e) => console.error(e)
        });
    }
  }
  updateContactChannel() {
    this.contact_channelService.update(this.currentContactChannel.id, this.p.title.value)
      .subscribe({
        next: (response) => {
          this.contact_channelSubmitted = false
          this.loadingSubmitContactChannel = false
          this.modalService.dismissAll()
          this.toastService.info('Niveau mis à jour', 'Niveau mis à jour avec succès')
          this.contact_channelForm.reset()
        },
        error: (e) => {
          this.loadingSubmitContactChannel = false
          this.contact_channelSubmitted = false
          console.error(e)
        }
      });
  }
  createContactChannel() {
    this.contact_channelSubmitted = true
    if (this.contact_channelForm.invalid) {
      return
    } else {
      this.loadingSubmitContactChannel = true
      this.contact_channelService.create(this.p.title.value)
        .subscribe({
          next: (response) => {
            this.contact_channelSubmitted = false
            this.loadingSubmitContactChannel = false
            this.modalService.dismissAll()
            this.toastService.success('Niveau créé', 'Niveau créé avec succès')
            this.contact_channelForm.reset()
          },
          error: (e) => {
            this.loadingSubmitContactChannel = false
            this.contact_channelSubmitted = false
            console.error(e)
          }
        });
    }
  }
  submitContactChannel() {
    if (this.currentContactChannel) {
      this.updateContactChannel()
    } else {
      this.createContactChannel()
    }
  }

  retrieveContactChannels(): void {
    this.contact_channelService.getAll()
      .subscribe({
        next: (data) => {
          this.contact_channels = data;
          this.loadedData = true
          this.tempContactChannelData = this.contact_channels;
          this.contact_channelRows = this.contact_channels;
          this.exportCSVData = this.contact_channels;
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.contact_channelService.refreshContactChannel.subscribe(() => {
      this.retrieveContactChannels()
    })
    this.retrieveContactChannels();

    //contact_channel
    this.contact_channelForm = this._formBuilder.group({
      title: ['', [Validators.required]],
    });
  }

  modalOpen(modalForm, contact_channel) {
    this.currentContactChannel = null
    this.contact_channelForm.reset()
    this.modalService.open(modalForm);
    if (contact_channel) {
      this.currentContactChannel = contact_channel
      this.contact_channelForm.patchValue({
        title: contact_channel.title,
        price: contact_channel.price,
      });
    }
  }

  filterContactChannels(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.tempContactChannelData.filter(function (d) {
      return d.title.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.contact_channelRows = temp;
  }

  confirmDeleteContactChannel(contact_channel) {
    Swal.fire({
      title: `Vous êtes sûr de vouloir supprimer ${contact_channel.title}?`,
      text: 'Vous ne serez pas en mesure de la récupérer!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Oui',
      cancelButtonText: 'Annuler',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(res => this.deleteContactChannel(res, contact_channel.id));
  }
}
