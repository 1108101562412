import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  private _refreshSubscription = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshSubscription() {
    return this._refreshSubscription;
  }

  getByPeriodAndSubjects(period, subjects) {
    return this._httpClient.post(`${environment.apiUrl}/api/subscription/list/${period}`, { subjects });
  }

  getByCustomer(customer_id, free) {
    return this._httpClient.get(`${environment.apiUrl}/api/subscription/${customer_id}/by_customer/${free}`);
  }

  create(customer, pack, bySubject, subjects, duration, start_date, end_date, price, payment_method, payment_date, comment, free) {
    return this._httpClient
      .post(`${environment.apiUrl}/api/subscription/add`, { customer, pack, bySubject, subjects, duration, start_date, end_date, price, payment_method, payment_date, comment, free })
      .pipe(
        tap(() => {
          this._refreshSubscription.next()
        })
      );
  }

  update(id, pack, bySubject, subjects, duration, start_date, end_date, price, payment_method, payment_date) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/subscription/${id}/edit`, { pack, bySubject, subjects, duration, start_date, end_date, price, payment_method, payment_date })
      .pipe(
        tap(() => {
          this._refreshSubscription.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/api/subscription/${id}/delete`)
      .pipe(
        tap(() => {
          this._refreshSubscription.next()
        })
      );
  }

}
