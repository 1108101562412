import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LevelService {

  private _refreshLevel = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshLevel() {
    return this._refreshLevel;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/api/level/list`);
  }

  create(title) {
    return this._httpClient
      .post(`${environment.apiUrl}/api/level/add`, { title })
      .pipe(
        tap(() => {
          this._refreshLevel.next()
        })
      );
  }

  update(id, title) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/level/${id}/edit`, { title })
      .pipe(
        tap(() => {
          this._refreshLevel.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/api/level/${id}/delete`)
      .pipe(
        tap(() => {
          this._refreshLevel.next()
        })
      );
  }

}
