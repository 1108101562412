import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Swal from 'sweetalert2';

import { ToastService } from 'app/service/toast/toast.service';
import { TimeFrameService } from 'app/service/time-frame/time-frame.service';

@Component({
  selector: 'app-time-frame',
  templateUrl: './time-frame.component.html',
  styleUrls: ['./time-frame.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TimeFrameComponent implements OnInit {

  public loadedData = false

  public timeFrames;
  public currentTimeFrame
  private tempTimeFrameData = [];
  public timeFrameRows: any;
  public exportCSVData

  public ColumnMode = ColumnMode;
  public basicSelectedOption: number = 25;
  public SelectionType = SelectionType;
  public selected = [];
  public chkBoxSelected = [];

  public timeFrameForm: FormGroup;
  public timeFrameSubmitted = false
  public loadingSubmitTimeFrame = false

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    private timeFrameService: TimeFrameService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
  ) { }

  // convenience getter for easy access to timeFrame form fields
  get t() {
    return this.timeFrameForm.controls;
  }

  deleteTimeFrame(res, id) {
    if (res.value == true) {
      this.timeFrameService.delete(id)
        .subscribe({
          next: (response) => {
            this.toastService.error('Niveau supprimé', 'Niveau supprimé avec succès')
          },
          error: (e) => console.error(e)
        });
    }
  }
  updateTimeFrame() {
    this.timeFrameService.update(this.currentTimeFrame.id, this.t.title.value, this.t.start_time.value, this.t.end_time.value)
      .subscribe({
        next: (response) => {
          this.timeFrameSubmitted = false
          this.loadingSubmitTimeFrame = false
          this.modalService.dismissAll()
          this.toastService.info('Niveau mis à jour', 'Niveau mis à jour avec succès')
          this.timeFrameForm.reset()
        },
        error: (e) => {
          this.loadingSubmitTimeFrame = false
          this.timeFrameSubmitted = false
          console.error(e)
        }
      });
  }
  createTimeFrame() {
    this.timeFrameSubmitted = true
    if (this.timeFrameForm.invalid) {
      return
    } else {
      this.loadingSubmitTimeFrame = true
      this.timeFrameService.create(this.t.title.value, this.t.start_time.value, this.t.end_time.value)
        .subscribe({
          next: (response) => {
            this.timeFrameSubmitted = false
            this.loadingSubmitTimeFrame = false
            this.modalService.dismissAll()
            this.toastService.success('Niveau créé', 'Niveau créé avec succès')
            this.timeFrameForm.reset()
          },
          error: (e) => {
            this.loadingSubmitTimeFrame = false
            this.timeFrameSubmitted = false
            console.error(e)
          }
        });
    }
  }
  submitTimeFrame() {
    if (this.currentTimeFrame) {
      this.updateTimeFrame()
    } else {
      this.createTimeFrame()
    }
  }

  retrieveTimeFrames(): void {
    this.timeFrameService.getAll()
      .subscribe({
        next: (data) => {
          this.timeFrames = data;
          this.loadedData = true
          this.tempTimeFrameData = this.timeFrames;
          this.timeFrameRows = this.timeFrames;
          this.exportCSVData = this.timeFrames;
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.timeFrameService.refreshTimeFrame.subscribe(() => {
      this.retrieveTimeFrames()
    })
    this.retrieveTimeFrames();

    //timeFrame
    this.timeFrameForm = this._formBuilder.group({
      title: ['', [Validators.required]], start_time: ['', [Validators.required]], end_time: ['', [Validators.required]],
    });
  }

  modalOpen(modalForm, timeFrame) {
    this.currentTimeFrame = null
    this.timeFrameForm.reset()
    this.modalService.open(modalForm);
    if (timeFrame) {
      this.currentTimeFrame = timeFrame
      this.timeFrameForm.patchValue({
        title: timeFrame.title,
        start_time: timeFrame.start_time,
        end_time: timeFrame.end_time,
      });
    }
  }

  filterTimeFrames(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.tempTimeFrameData.filter(function (d) {
      if (d.title.toLowerCase().includes(val) || d.start_time.includes(val) || d.end_time.includes(val)) {
        return d
      }
    });
    this.timeFrameRows = temp;
  }

  confirmDeleteTimeFrame(timeFrame) {
    Swal.fire({
      title: `Vous êtes sûr de vouloir supprimer ${timeFrame.title}?`,
      text: 'Vous ne serez pas en mesure de la récupérer!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Oui',
      cancelButtonText: 'Annuler',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(res => this.deleteTimeFrame(res, timeFrame.id));
  }
}
