<div class="card">
    <div
        class="card-header d-flex flex-sm-row flex-column justify-content-md-between align-items-start justify-content-start">
        <div>
            <h4 class="card-title mb-25">Histogramme DMT (Durée moyenne de traitement par minute)</h4>
        </div>
        <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
            <span class="badge badge-light-primary" *ngIf="callDurationData && callDurationData.total">{{callDurationData.total}} clients</span>

            <!-- <ng2-flatpickr [config]="DateRangeOptions" name="DateRange" [(ngModel)]="period"
                (ngModelChange)="retrieveByLevel()" class="mr-1"></ng2-flatpickr> -->
        </div>
    </div>
    <div class="card-body" *ngIf="callDurationData">
        <div id="bar-chart" #callDurationRef>
            <apx-chart [series]="callDuration.series" [chart]="callDuration.chart" [dataLabels]="callDuration.dataLabels"
                [plotOptions]="callDuration.plotOptions" [xaxis]="callDuration.xaxis" [colors]="callDuration.colors"
                [grid]="callDuration.grid"></apx-chart>
        </div>
    </div>
</div>