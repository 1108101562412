<div class="text-center" *ngIf="!loadedData">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<div class="content-wrapper container-xxl p-0" *ngIf="loadedData">
    <div class="content-body">
        <div class="card">
            <div class="card-header">
                <h3 class="text-primary badge badge-light-primary" style="padding: 1rem 1rem;font-size: 120%;">
                    <i data-feather="list" class="font-medium-3"></i> Liste des séances gratuites
                </h3>
                <div class="card-action">
                    <div class="btn-group btn-group-toggle">
                        <label class="btn{{currentPeriod == 'past' ? '-':'-outline-'}}primary" ngbButtonLabel
                            rippleEffect>
                            <input type="checkbox" ngbButton (click)="changePeriod('past')" />Précédentes
                        </label>
                        <label class="btn{{currentPeriod == 'future' ? '-':'-outline-'}}primary" ngbButtonLabel
                            rippleEffect>
                            <input type="checkbox" ngbButton (click)="changePeriod('future')" />Futures
                        </label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-12">
                    <div class="d-flex justify-content-between align-items-center m-1">
                        <label class="d-flex align-items-center">Afficher
                            <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                                <option value="5">5</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            entrées</label>
                    </div>
                </div>
                <div class="col-md-8 col-12 d-flex justify-content-md-end">
                    <div class="col-md-5">
                        <ng-select [(ngModel)]="selectedSubjects" placeholder="Matières" multiple="true"
                            (change)="retrieveFreeSessions()">
                            <ng-option *ngFor="let subject of subjects"
                                [value]="subject.id">{{subject.title}}</ng-option>
                        </ng-select>
                    </div>
                    <div class="col-md-3">
                        <label class="d-flex align-items-center"><input type="search" placeholder="recherche"
                                class="form-control ml-25" (keyup)="filterFreeSessions($event)"
                                (search)="filterFreeSessions($event)" /></label>
                    </div>
                </div>
            </div>

            <!-- FreeSessions Datatable -->
            <div class="d-flex justify-content-center my-1">
                <span *ngIf="!loadedDatatable" class="spinner-border spinner-border-sm"></span>
            </div>
            <ngx-datatable [rows]="freeSessionRows" [rowHeight]="58" class="bootstrap core-bootstrap" [limit]="5"
                [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
                [selectionType]="SelectionType.checkbox" [limit]="basicSelectedOption"
                (select)="onSelectFreeSession($event)" *ngIf="loadedDatatable">
                <ngx-datatable-column name="Date" [width]="150">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        <strong>Le </strong>{{row.date}}<br>
                        <strong>à </strong>{{row.time}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Matière" prop="subject.title" [width]="100"></ngx-datatable-column>
                <ngx-datatable-column name="Client" [width]="150">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        {{row.customer.first_name}} {{row.customer.last_name}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Actions" [width]="120" [sortable]="false">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        <div class="d-flex align-items-center" routerLink="/customer/{{row.customer.id}}/detail">
                            <button type="button" class="btn btn-raised btn-outline-primary btn-sm mr-1" rippleEffect>
                                <i data-feather="file-text"></i>
                            </button>
                        </div>
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
            <!--/ FreeSessions Datatable -->
        </div>
    </div>
</div>