<div class="content-wrapper container-xxl p-0" *ngIf="global_period">
    <div class="content-body">
        <div class="card">
            <div class="card-header">
                <h3 class="text-primary" style="font-size: 120%;">
                    <span class="badge badge-light-primary mr-1" style="padding: 1rem 1rem;border: 2px solid #2a0d49;" role="button" routerLink="/dashboard-seller/{{seller}}"><i data-feather="bar-chart-2" class="font-medium-3"></i> Statistiques Vendeur: {{salesFigures && salesFigures.seller_name ? salesFigures.seller_name:''}}</span>
                    <span *ngIf="currentUser.role == 'ROLE_USER' || currentUser.role == 'ROLE_SELLER' || currentUser.role == 'ROLE_SELLER_CENTER'" class="badge badge-light-primary mr-1" style="padding: 1rem 1rem;" role="button" routerLink="/seller_contact/{{seller}}"><i data-feather="phone-outgoing" class="font-medium-3"></i> Historique des contacts</span>
                    <span *ngIf="currentUser.role == 'ROLE_USER' || currentUser.role == 'ROLE_SELLER' || currentUser.role == 'ROLE_SELLER_CENTER'" class="badge badge-light-primary" style="padding: 1rem 1rem;" role="button" routerLink="/seller_history/{{seller}}"><i data-feather="list" class="font-medium-3"></i> Historique changement de statut</span>
                </h3>
                <div class="card-action">
                    <ng2-flatpickr [config]="globalDateOptions" name="globalDateRange" [(ngModel)]="period"
                        (ngModelChange)="changePeriod()" class="mr-1"></ng2-flatpickr>
                </div>
            </div>
        </div>
        <div class="row">
            <app-number [period]="global_period" [disable_period]="false" [campaign]="null" [seller]="seller"
                class="col-10"></app-number>
            <div class="col-2 card border-success" *ngIf="salesFigures">
                <div class="card-body">
                    <div class="media">
                        <div class="avatar bg-light-success p-1 mr-2 mb-1 rounded">
                            <div class="avatar-content">
                                <i data-feather="dollar-sign" [size]="25"></i>
                            </div>
                        </div>
                        <div class="media-body my-auto">
                            <h4 class="font-weight-bolder mb-0">{{salesFigures.amount}} Dt</h4>
                            <p class="card-text font-small-3 mb-0">Chiffre d'affaires</p>
                        </div>
                    </div>
                    <div class="media">
                        <div class="avatar bg-light-success p-1 mr-2 rounded">
                            <div class="avatar-content">
                                <i data-feather="clock" [size]="25"></i>
                            </div>
                        </div>
                        <div class="media-body my-auto">
                            <h4 class="font-weight-bolder mb-0">{{salesFigures.seller_duration_average}} Min</h4>
                            <p class="card-text font-small-3 mb-0">Durée de traitement</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-customer-conversion [period]="global_period" [disable_period]="false" [campaign]="null"
            [seller]="seller"></app-customer-conversion>
        <app-customer-contacted-and-converted [period]="global_period" [disable_period]="false" [campaign]="null"
            [seller]="seller"></app-customer-contacted-and-converted>
        <app-customer-reachability [period]="global_period" [disable_period]="false" [campaign]="null"
            [seller]="seller"></app-customer-reachability>
        <app-call-duration-histogram [period]="global_period" [disable_period]="false" [campaign]="null"
            [seller]="seller"></app-call-duration-histogram>

        <app-contact-type [period]="global_period" [disable_period]="false" [campaign]="null" [seller]="seller"
            class="col-7"></app-contact-type>

        <div class="row">
            <app-by-governorate [period]="global_period" [disable_period]="false" [campaign]="null" [seller]="seller"
                class="col-6"></app-by-governorate>
            <app-by-contact-channel [period]="global_period" [disable_period]="false" [campaign]="null"
                [seller]="seller" class="col-6"></app-by-contact-channel>
        </div>
        <div class="row">
            <app-by-level [period]="global_period" [disable_period]="false" [campaign]="null" [seller]="seller"
                class="col-6"></app-by-level>
            <app-by-gender [period]="global_period" [disable_period]="false" [campaign]="null" [seller]="seller"
                class="col-6"></app-by-gender>
        </div>
    </div>
</div>