<div class="card">
    <div
        class="card-header d-flex flex-sm-row flex-column justify-content-md-between align-items-start justify-content-start">
        <div>
            <h4 class="card-title mb-25">Distribution des clients selon les niveaux</h4>
        </div>
        <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
            <span class="badge badge-light-primary" *ngIf="byLevelData && byLevelData.total">{{byLevelData.total}} clients</span>

            <!-- <ng2-flatpickr [config]="DateRangeOptions" name="DateRange" [(ngModel)]="period"
                (ngModelChange)="retrieveByLevel()" class="mr-1"></ng2-flatpickr> -->
        </div>
    </div>
    <div class="card-body" *ngIf="byLevelData">
        <div id="bar-chart" #byLevelRef>
            <apx-chart [series]="byLevel.series" [chart]="byLevel.chart" [dataLabels]="byLevel.dataLabels"
                [plotOptions]="byLevel.plotOptions" [xaxis]="byLevel.xaxis" [colors]="byLevel.colors"
                [grid]="byLevel.grid"></apx-chart>
        </div>
    </div>
</div>