import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Swal from 'sweetalert2';

import { RoleService } from 'app/service/role/role.service';
import { ToastService } from 'app/service/toast/toast.service';
import { ParameterService } from 'app/service/parameter/parameter.service';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RoleComponent implements OnInit {

  public loadedData = false

  public roles;
  public currentRole

  public pages
  public access

  public roleForm: FormGroup;
  public roleSubmitted = false
  public loadingSubmitRole = false

  pagesFormHolder = [];

  constructor(
    private roleService: RoleService,
    private parameterService: ParameterService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder
  ) { }

  // convenience getter for easy access to role form fields
  get r() {
    return this.roleForm.controls;
  }

  deleteRole(res, id) {
    if (res.value == true) {
      this.roleService.delete(id)
        .subscribe({
          next: (response) => {
            this.toastService.error('Role supprimé', 'Role supprimé avec succès')
          },
          error: (e) => console.error(e)
        });
    }
  }
  updateRole() {
    this.roleService.update(this.currentRole.id, this.r.title.value, this.access)
      .subscribe({
        next: (response) => {
          this.roleSubmitted = false
          this.loadingSubmitRole = false
          this.modalService.dismissAll()
          this.toastService.info('Role mis à jour', 'Role mis à jour avec succès')
          this.roleForm.reset()
        },
        error: (e) => {
          this.loadingSubmitRole = false
          this.roleSubmitted = false
          console.error(e)
        }
      });
  }
  submitRole() {
    this.createAccess()
    if (this.currentRole) {
      this.updateRole()
    }
  }

  retrievePages(): void {
    this.parameterService.getByCode('pages')
      .subscribe({
        next: (data) => {
          this.pages = data;
        },
        error: (e) => console.error(e)
      });
  }

  retrieveRoles(): void {
    this.roleService.getAll()
      .subscribe({
        next: (data) => {
          this.roles = data;
          this.loadedData = true
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.roleService.refreshRole.subscribe(() => {
      this.retrieveRoles()
    })
    this.retrieveRoles();
    this.retrievePages();

    //role
    this.roleForm = this._formBuilder.group({
      title: ['', [Validators.required]],
    });
  }

  modalOpen(modalForm, role) {
    this.currentRole = null
    this.roleForm.reset()
    this.pagesFormHolder = []
    this.pagesFormHolder.push(this._formBuilder.group({
      pageTitle: new FormControl(null), pageActions: new FormControl(null),
    }))
    this.modalService.open(modalForm, {
      centered: true,
      size: 'lg',
    });
    if (role) {
      if (role.access.length > 0) {
        this.pagesFormHolder = []
      }
      this.currentRole = role
      this.roleForm.patchValue({
        title: role.title,
      });
      role.access.forEach(element => {
        let newForm = {
          pageTitle: new FormControl(element.pageTitle), pageActions: new FormControl(element.pageActions),
        }
        var builtForm = this._formBuilder.group(newForm)
        builtForm.markAllAsTouched()
        this.pagesFormHolder.push(builtForm)
      });
    }
  }

  addPage() {
    let newForm = {
      pageTitle: new FormControl(''), pageActions: new FormControl(''),
    }
    this.pagesFormHolder.push(this._formBuilder.group(newForm))
  }
  deletePage(toDelete) {
    let aux: any[] = [];
    for (let form of this.pagesFormHolder) {
      if (toDelete !== form) {
        aux.push(form);
      }
    }
    this.pagesFormHolder = aux;
  }

  confirmDeleteRole(role) {
    Swal.fire({
      title: `Vous êtes sûr de vouloir supprimer ${role.title}?`,
      text: 'Vous ne serez pas en mesure de la récupérer!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Oui',
      cancelButtonText: 'Annuler',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(res => this.deleteRole(res, role.id));
  }

  createAccess() {
    this.access = [];
    this.pagesFormHolder.forEach(form => {
      if (form.get('pageTitle').touched && form.get('pageTitle').value != null) {
        this.access.push({
          pageTitle: form.get('pageTitle').value,
          pageActions: form.get('pageActions').value,
        })
      }
    });
  }

}
