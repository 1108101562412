import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerStatusHistoryService {

  private _refreshHistory = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshHistory() {
    return this._refreshHistory;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/api/history/list`);
  }

  getByUser(user_id, startDate, endDate, contact_statut, customer_statut) {
    return this._httpClient.post(`${environment.apiUrl}/api/history/${user_id}/by_user`, { startDate, endDate, contact_statut, customer_statut });
  }

}
