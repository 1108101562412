import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  static role
  private _refreshRole = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshRole() {
    return this._refreshRole;
  }
  
  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/api/role/list`);
  }

  getRoles(){
    return this._httpClient.get(`${environment.apiUrl}/api/role/by_page`);
  }

  create(title, access) {
    return this._httpClient
      .post(`${environment.apiUrl}/api/role/add`, { title, access })
      .pipe(
        tap(() => {
          this._refreshRole.next()
        })
      );
  }

  update(id, title, access) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/role/${id}/edit`, { title, access })
      .pipe(
        tap(() => {
          this._refreshRole.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/api/role/${id}/delete`)
      .pipe(
        tap(() => {
          this._refreshRole.next()
        })
      );
  }
}
